import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Intentamos cargar el carrito desde localStorage al inicio
  const storedCart = JSON.parse(localStorage.getItem('cart')) || [];

  const [cart, setCart] = useState(storedCart); // Estado del carrito, inicializado con los datos de localStorage si existen

  useEffect(() => {
    // Guardamos el carrito en localStorage cada vez que cambie
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]); // Solo se ejecuta cuando cart cambia

  const addToCart = (product) => {
    setCart((prev) => [...prev, product]);
  };

  const removeFromCart = (productId) => {
    setCart((prev) => prev.filter((item) => item.productId !== productId)); // Asegúrate de que el campo sea 'productId'
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};