// material-ui
// project-imports
import { useCart } from '../../hooks/useCart';
import MainCard from "components/MainCard";
import LogoIcon from "components/logo/LogoIcon";
import arrow from "assets/images/arrow.png";
import React, { useState, useEffect } from "react";
import { Card, CardContent,useMediaQuery, Typography, Button, IconButton, TextField, Box, Grid, Stack, Slide, Pagination, Divider  } from "@mui/material";
import { border, borderColor, height } from "@mui/system";
import usePagination from "hooks/usePagination";
import { GlobalFilter } from "utils/react-table";
import Slider from "react-slick";




// ==============================|| SAMPLE PAGE ||============================== //

const Catalog = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [sortBy, setSortBy] = useState("Default");
  const [globalFilter, setGlobalFilter] = useState("");
  const [add, setAdd] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [userCard, setUserCard] = useState([]);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]); 
  const [quantities, setQuantities] = useState({}); 
  const [productQuantities, setProductQuantities] = useState({});
  const [cart, setCart] = useState([]);
  const [agregado, setAgregado] = useState({});
 


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


    const PER_PAGE = 10;
    useEffect(() => {
      fetch("http://localhost:82/api/product")
        .then(response => response.json())
        .then(data => {

          if (Array.isArray(data.data)) {
            setProducts(data.data); 
          } else {
            console.error("La respuesta no contiene un array válido de productos.");
            setProducts([]); 
          }
        })
        .catch(error => {
          console.error("Error fetching products:", error);
          setProducts([]); 
        });
    }, []);

    const handleAddProduct = (id) => {
      setAddedProducts((prev) => [...prev, id]); 
      console.log("Producto añadido con ID:", id); 
    };

    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(globalFilter.toLowerCase())
      );
      setFilteredProducts(filtered);
    }, [globalFilter, products]);

    const addToCart = (productId, quantity, name, details, price) => {
      console.log("Producto ID:", productId);  
      console.log("Cantidad:", quantity);
    
      if (quantity <= 0) {
        alert("La cantidad debe ser mayor a 0");
        return;
      }
    

      const productDetails = products.find((product) => product._id === productId);
      if (!productDetails) {
        console.error("Producto no encontrado");
        return; 
      }
    
      setCart((prevCart) => {
        console.log("Carrito anterior:", prevCart);  
    
        const existingProduct = prevCart.find((item) => item.productId === productId);
        console.log("Producto existente:", existingProduct); 
    
        if (existingProduct) {

          const updatedCart = prevCart.map((item) =>
            item.productId === productId
              ? { ...item, quantity: item.quantity + quantity } 
              : item
          );
          console.log("Carrito actualizado con producto existente:", updatedCart);  
          return updatedCart;
        }

        const newCart = [
          ...prevCart,
          {
            productId,
            quantity,
            name: productDetails.name,  
            details: productDetails.details ,
            price:productDetails.price,
            sellPrice: productDetails.sellPrice,
            importado: productDetails.importado,
            image: productDetails.image
          }
        ];
        
        
        localStorage.setItem("cart", JSON.stringify(newCart));
        console.log("Carrito actualizado con nuevo producto:", newCart); 
        return newCart;
      });
    

      setProductQuantities((prev) => {
        const newQuantities = { ...prev, [name]: 0 }; 
        return newQuantities;
      });

      setAgregado((prev) =>
        ({
        ...prev,
        [productId]: true, 
      }));
    };
    const handleDecreaseQuantity = (productId) => {
      setProductQuantities((prev) => {
        const newQuantity = (prev[productId] || 0) > 0 ? prev[productId] - 1 : 0;
        return {
          ...prev,
          [productId]: newQuantity,
        };
      });
    };

    const handleQuantityChange = (productId, newQuantity) => {
      setProductQuantities((prev) => ({
        ...prev,
        [productId]: newQuantity,
      }));
    };

    const handleIncreaseQuantity = (productId) => {
      setProductQuantities((prev) => {
        const newQuantity = (prev[productId] || 0) + 1;
        return {
          ...prev,
          [productId]: newQuantity,
        };
      });
    };
  
  

    const count = Math.ceil(products.length / PER_PAGE);
    const _DATA = usePagination(products, PER_PAGE);

    const handleChangePage = (e, p) => {
      setPage(p);
      _DATA.jump(p);
    };
    const paginatedProducts = filteredProducts.slice((page - 1) * PER_PAGE, page * PER_PAGE);

    return (
  <div>
    {/* <MainCard title="Em Desenvolvimento">
    <Typography variant="body1">
      Nos próximos dias estaremos disponibilizando novas funcionalidades.
    </Typography>
  </MainCard> */}
    <div>
    <Typography variant="h3" sx ={{ml: "16px", fontWeight:"800", fontSize:"40px" }}>
            Catalog : Nombre de Proveedor
        </Typography>
        <Typography variant="h6" sx={{ml: "16px", mb: "16px" }}>
         In this section you can find the products available from Kanpaï suppliers.
         </Typography>  
    <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }} style={{ padding: "16px" }}>
    
    <Slider {...settings} style={{ width: "100%", marginTop:"20px" }}>
      {/* Slide 1 */}
      <div>
        <Box
          style={{
            backgroundColor: "#313A13",
            borderColor: "#313A13",
            padding: "16px",
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "100%", 
            minHeight: "350px",
            height: "auto", 
          }}
        >
          {/* Aquí puedes poner contenido */}
        </Box>
      </div>

      {/* Slide 2 */}
      <div>
        <Box
          style={{
            backgroundColor: "#313A13",
            borderColor: "#313A13",
            padding: "16px",
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "100%",
            minHeight: "350px",
            height: "auto",
          }}
        >

        </Box>
      </div>

      {/* Slide 3 */}
      <div>
        <Box
          style={{
            backgroundColor: "#313A13",
            borderColor: "#313A13",
            padding: "16px",
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "100%",
            minHeight: "350px", 
            height: "auto",
          }}
        >

        </Box>
      </div>

      {/* Slide 4 */}
      <div>
        <Box
          style={{
            backgroundColor: "#313A13",
            borderColor: "#313A13",
            padding: "16px",
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "100%",
            minHeight: "350px", 
            height: "auto",
          }}
        >

        </Box>
      </div>
    </Slider>

    </Grid>
              <TextField
                label="Search Products"
                variant="outlined"
                fullWidth
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                sx={{ marginBottom: 3, width: "30%" }}
              />
              <Divider></Divider>
              <Typography variant="h3" sx={{ marginTop: "10px", marginBottom: 2 }}>
                Products
              </Typography>
            <Grid container spacing={3} columns={10}>
            {paginatedProducts.length > 0 ? (
              paginatedProducts.map((product, index) => {
                
                const price = parseFloat(product.price);
                const sellPrice = parseFloat(product.sellPrice); 
          
                let discountPercentage = null;
                  if (!isNaN(price) && price > 0 && product.sellPrice > 0 && price > sellPrice) {
                    discountPercentage = ((price - product.sellPrice) / price) * 100;
                  }

                  
                  const formattedDiscount = discountPercentage !== null ? `${Math.round(discountPercentage)}%` : null;

                  
                  const showDiscount = price > sellPrice;
          
                return (
                  <Slide key={index} direction="up" in={true} timeout={50}>
                    <Grid item xs={10} sm={5} lg={2}>
                      <Card
                        style={{
                          padding: '16px',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderRadius: '12px',
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                          backgroundColor: '#fff',
                          height: '100%',
                          position: 'relative',
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: '8px',
                            left: '8px',
                            display: 'flex',
                            gap: '8px',
                          }}
                        >
                          {formattedDiscount && (
                            <span
                              style={{
                                backgroundColor: 'rgba(255, 194, 102, 0.5)',
                                color: 'rgba(117, 70, 0, 1)',
                                padding: '4px 8px',
                                borderRadius: '8px',
                                fontSize: '12px',
                                fontWeight: '500',
                              }}
                            >
                             {formattedDiscount}
                            </span>
                          )}
                         {product.importado && (
                            <span
                              style={{
                                backgroundColor: 'rgba(63, 81, 181, 0.1)',
                                color: 'rgba(87, 99, 232, 1)',
                                padding: '4px 8px',
                                borderRadius: '8px',
                                fontSize: '12px',
                                fontWeight: '500',
                              }}
                            >
                              Importado
                            </span>
                          )}
                        </div>

                        <img
                          src={`/images/inventoryImagenes/${product.image}`}
                          alt={product.name}
                          style={{ width: '100px', height: '100px', objectFit: 'contain', marginBottom: '8px', marginTop: '25px' }}
                        />

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                            borderBottom: '1px solid #b1b4b9',
                            paddingBottom: '2px',
                          }}
                        >
                          {showDiscount && formattedDiscount &&  (
                            <Typography
                              variant="body2"
                              style={{
                                textDecoration: 'line-through',
                                fontSize: '14px',
                                color: 'gray',
                              }}
                            >
                               € {price.toFixed(2)}
                            </Typography>
                          )}
                          <Typography
                            variant="h5"
                            color="textPrimary"
                            style={{
                              fontWeight: '700',
                              fontSize: formattedDiscount ? '20px' : '16px',  
                              color: formattedDiscount ? '#FF5722' : '#000', 
                            }}
                          >
                             € {formattedDiscount ? sellPrice.toFixed(2) : price.toFixed(2)}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontSize: '16px', fontWeight: '700', textAlign: 'left' }}>
                            {product.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" style={{ textAlign: 'left', fontSize: '0.70rem' }}>
                            {product.details}
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '12px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              border: '1px solid #d1d5db',
                              borderRadius: '12px',
                              padding: '2px 4px',
                              backgroundColor: '#f9fafb',
                              width: 'fit-content',
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleDecreaseQuantity(product._id)}
                              style={{
                                backgroundColor: '#e5e7eb',
                                borderRadius: '50%',
                                padding: '2px',
                                width: '24px',
                                height: '24px',
                                fontSize: '12px',
                              }}
                            >
                              -
                            </IconButton>
                            <TextField
                              value={productQuantities[product._id] || 0}
                              onChange={(e) => handleQuantityChange(product._id, parseInt(e.target.value) || 0)}
                              inputProps={{
                                style: { fontSize: '12px', textAlign: 'center', padding: '0', width: '32px', height: '24px' },
                              }}
                              style={{
                                width: '40px',
                                margin: '0 4px',
                              }}
                            />
                            <IconButton
                              size="small"
                              onClick={() => handleIncreaseQuantity(product._id)}
                              style={{
                                backgroundColor: '#e5e7eb',
                                borderRadius: '50%',
                                padding: '2px',
                                width: '24px',
                                height: '24px',
                                fontSize: '12px',
                              }}
                            >
                              +
                            </IconButton>
                          </div>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: agregado[product._id] ? 'rgba(253, 237, 231, 1)' : '#FF5722',
                              color: agregado[product._id] ? '#FF5722' : '#fff',
                              padding: '6px 12px',
                              borderRadius: '8px',
                            }}
                            onClick={() => {
                              const quantity = productQuantities[product._id] || 1;
                              if (quantity <= 0) {
                                alert('La cantidad debe ser mayor a 0');
                                return;
                              }
                              addToCart(product._id, quantity);
                            }}
                          >
                            {agregado[product._id] ? 'Añadido' : 'Añadir'}
                          </Button>
                        </div>
                      </Card>
                    </Grid>
                  </Slide>
                );
              })
            ) : (
              <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', width: '100%' }}>
                No se encontraron productos
              </Typography>
            )}
          </Grid>
            
              {/* Paginación */}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                <Pagination
                  count={Math.ceil(products.length / PER_PAGE)}
                  page={page}
                  onChange={handleChangePage}
                  color="primary"
                />
              </Box>
    </div>
  </div>
  )
}
export default Catalog;
