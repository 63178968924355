import React from 'react';
import { Typography, Button, Grid, Box } from '@mui/material';
import Calendar from 'react-calendar'; // Si usas una librería de calendario
import 'react-calendar/dist/Calendar.css';
import { ArrowCircleRight2 } from "iconsax-react";

const CalendarDashboard = () => {
  const events = [
    {
      date: '2024-01-06',
      description: 'Recordatorio de IA: está previsto que para esta fecha se te acabe el stock de Cerveza Mahou',
      actionText: 'Realizar orden',
    },
    {
      date: '2024-01-28',
      description: 'Pago pendiente a Licoteca.',
      actionText: 'Revisar Pago',
    },
    {
      date: '2024-01-29',
      description: 'Recordatorio de IA: está previsto que para esta fecha se te acabe el stock de Cerveza Mahou',
      actionText: 'Realizar orden',
    },
  ];

  return (
    <Box sx={{ padding: '16px', backgroundColor: '#fff', color: '#000', minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Título Dashboard */}
        <Typography style={{ fontSize: "15px", fontWeight: "500", color: "gray" }} variant="h4" gutterBottom>
          Dashboard
        </Typography>

        {/* Título Calendario a la derecha */}
        <Typography style={{ fontSize: "15px", fontWeight: "500", color: "black", marginLeft: "2px" }} variant="h4" gutterBottom>
          /Calendario
        </Typography>
      </Box>      <Typography style={{fontSize:"35px", fontWeight:"700"}} variant="h4" gutterBottom>
        Calendario
      </Typography>
      <Typography style={{fontWeight:"400", color:"rgba(22, 23, 29, 1)"}} variant="subtitle1" gutterBottom>
        Organiza y gestiona tus fechas más importantes.
      </Typography>

      <Box sx={{ backgroundColor: 'rgba(248, 247, 253, 0.6)', padding: '16px', borderRadius: '8px', marginBottom: '24px' }}>
        <Typography style={{fontWeight:"600", fontSize:"20px"}} variant="h6" gutterBottom>
          Fechas importantes
        </Typography>
        <Grid container spacing={2}>
          {events.map((event, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  backgroundColor: 'rgba(248, 247, 253, 0.6)',
                  padding: '12px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1" sx={{ color: '#000' }}>
                  <span style={{ color: 'rgba(240, 83, 28, 1)', fontWeight: 'bold', marginRight: '8px' }}>
                    {new Date(event.date).getDate()}
                  </span>
                  {event.description}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: 'rgba(240, 83, 28, 1)', color: '#fff' }}
                  onClick={() => alert(`Acción: ${event.actionText}`)}
                >
                  {event.actionText} <ArrowCircleRight2 style={{marginLeft:"4px"}}/>
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
        <Typography style={{fontWeight:"800", fontSize:"20px"}} variant="h6" gutterBottom>
          Enero 2024
        </Typography>
        <Calendar
          locale="es-ES"
          tileClassName={({ date }) => {
            const formattedDate = date.toISOString().split('T')[0];
            return events.some((event) => event.date === formattedDate) ? 'highlight' : '';
          }}
        />
      </Box>
    </Box>
  );
};

export default CalendarDashboard;