import {
    serverCallDelete,
    serverCallGet,
    serverCallPost,
    serverCallURL,
    serverCallPut,
  } from "./serverCall";
  
  export const APICall = {
    verify: () => {
      return serverCallGet("/auth/verify");
    },

    
    getProductEstimate: (data) => {
      return serverCallPost("/predict",data,null,null,"https://api.kanpaiapp.com.es");
    },
    addSalesReport: (data) => {
      return serverCallPut("/auth/add-sales-report",data);
    },
    getProductCategories: () => {
      return serverCallGet("/product/categories");
    },
    
    getProductsByUserReport: () => {
      return serverCallGet("/product/by-report");
    },
    getProductImage: (id) => {
      return serverCallURL("/product/image/" + id);
    },
    oneTapGoogleSignIn:(tokenId)=>{
      return serverCallPost("/auth/login-gmail",{tokenId})
    },
    register:({email,name,password})=>{
      return serverCallPost("/auth/register",{email,name,password})
    },
    login:({email,password})=>{
      return serverCallPost("/auth/login",{email,password})
    },
    forgotPassword:(email)=>{
      return serverCallPost("/auth/forgot-pass",{email})
    },
    resetPassword:({password,id})=>{
      return serverCallPost("/auth/reset-pass",{password,id})
    },
    completeRegistration: ({
      name,
      lastName,
      telephone,
      email,
      idNumber,
      role,
      barOwner,
      supplier,
      importer,
    }) => {
      const payload = {
        name,
        lastName,
        telephone,
        email,
        idNumber,
        role,
        barOwner,
        supplier,
        importer,
      };
    
      if (role === "BarOwner") {
        payload.isAlreadyOpen = barOwner.isAlreadyOpen;
        payload.barName = barOwner.barName;
        payload.barContactPhone = barOwner.barContactPhone;
        payload.barEmail = barOwner.barEmail;
        payload.barAddress = barOwner.barAddress;
        payload.businessIdNumber = barOwner.businessIdNumber;
        payload.barType = barOwner.barType;
      } else if (role === "Supplier") {
        if (supplier && supplier.businessName) {
          payload.businessName = supplier.businessName;
          payload.businessRegistrationNumber = supplier.businessRegistrationNumber;
          payload.supplierEmail = supplier.supplierEmail;
          payload.supplierContactPhone = supplier.supplierContactPhone;
          payload.itemsSupplied = supplier.itemsSupplied;
          payload.regionsOfOperation = supplier.regionsOfOperation;
          payload.monthlyFigures = supplier.monthlyFigures;
          payload.mostSoldProducts = supplier.mostSoldProducts;
        } else {
          throw new Error("Supplier business name is missing");
        }
      }else if (role === "Importer") {
        payload.businessName = importer.businessName;
        payload.businessRegistrationNumber = importer.businessRegistrationNumber;
        payload.importerEmail = importer.importerEmail;
        payload.importerContactPhone = importer.importerContactPhone;
        payload.itemsSupplied = importer.itemsSupplied;
        payload.regionsOfOperation = importer.regionsOfOperation;
        payload.monthlyFigures = importer.monthlyFigures;
        payload.mostSoldProducts = importer.mostSoldProducts;
      }
      
      // Realiza la llamada a la API con el payload
      return serverCallPut(`/auth/complete-registration/`, payload);
    },
    getProducts: () => {
      // Función para obtener los productos
      return serverCallGet("/product");
    },
  };