import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery } from '@mui/material';
import { TickCircle,  Wallet } from 'iconsax-react';

import { useTheme } from '@mui/material/styles';
import Confetti from "react-confetti";

const OrderRequest = ({ open }) => {
  const [openReject, setOpenReject] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false); 
  const [order, setOrder] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState([]);
  const [add, setAdd] = useState(false);
  const [cartItems, setCartItems] = useState([]);


  const removeFromCart = (productId) => {
    setData((prevCart) => {
      const updatedCart = prevCart.filter((item) => item.productId !== productId);
      localStorage.setItem("cart", JSON.stringify(updatedCart)); 
      return updatedCart; 
    });
  };
  const handleRemoveItem = (productId) => {

    removeFromCart(productId);
  };


  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    console.log(savedCart);
    if (savedCart) {

      setData(JSON.parse(savedCart));
    } else {

      fetch("http://localhost:82/api/cart")//este fetch no existe los archivos estan en localStorage
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data.data)) {
            setData(data.data);
           
            localStorage.setData("cart", JSON.stringify(data.data));
          } else {
            console.error("La respuesta no contiene un array válido de items del carrito.");
            setData([]);
          }
        })
        .catch(error => {
          console.error("Error fetching cart items:", error);
          setData([]);
        });
    }
  }, [open]);


  const handleRejectOpen = () => setOpenReject(true);
  const handleRejectClose = () => setOpenReject(false);

  const handleConfirmOpen = () => {
    setOpenConfirm(true);
    setShowConfetti(true); 
  };

  const handleConfirmClose = () => setOpenConfirm(false);

  // Formatear fecha
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  useEffect(() => {
    fetch("http://localhost:82/api/order")
      .then(response => response.json())
      .then(data => {
        console.log(data); 
        if (Array.isArray(data.data)) {
          setOrder(data.data);
          console.log("Orders fetched successfully:", data.data);
        } else {
          console.error("La respuesta no contiene un array válido de orders.");
          setOrder([]);
        }
      })
      .catch(error => {
        console.error("Error fetching order:", error);
        setOrder([]);
      });
  }, []);

  return (
    <Box
      open={open}
      sx={{
        backgroundColor: '#F9F6FF',
        padding: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {order.length > 0 ? (
        <>
          {/* Encabezado */}
          <Typography
            variant="h5"
            fontWeight="700"
            fontSize={isSmallScreen ? '24px' : '32px'}
            sx={{ mb: 1 }}
          >
            Order Requested 
          </Typography>

          {/* Detalles del pedido */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              justifyContent: 'space-between',
              gap: 4,
            }}
          >
            {/* Información de la orden */}
            <Box>
            <Typography variant="body1" sx={{ mb: 0, fontWeight:"800", fontSize:"20px" }}>
                Order details 
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Order created <span style={{ color: 'black', fontWeight: '600' }}>{formatDate(order[0].createdAt)}</span>
              </Typography>
              <Typography variant="body1">
                Supplier: <span style={{ color: 'black', fontWeight: '600' }}>Supplier name</span>
              </Typography>
              <Typography variant="body1">
                Order status: <span style={{ color: 'black', fontWeight: '600' }}>{order[0].status}</span>
              </Typography>
              <Typography variant="body1">Payment Method: <b>{order[0].paymentMethod}</b></Typography>
              <Typography variant="body1">
                Notas del cliente: <span style={{ color: '#444' }}>{order[0].customerNotes}</span>
              </Typography>
              <Typography variant="body1">
                Plazo de entrega: <span style={{ color: 'black', fontWeight: '600' }}>{formatDate(order[0].deliveryDeadline)}</span>
              </Typography>
            </Box>

            {/* Resumen de precios y descuento */}
            <Box
              sx={{
                marginTop: isSmallScreen ? 0 : '-60px',
                padding: isSmallScreen ? 2 : '60px 50px 50px !important',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 1,
                flex: isSmallScreen ? 1 : 0.5,
              }}
            >
              <Box
                sx={{
                  marginTop: isSmallScreen ? 0 : '-20px',
                  backgroundColor: '#E2DAF5',
                  borderRadius: 1,
                  p: 2,
                  mb: 2,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    placeholder="Discount %"
                    variant="outlined"
                    size="small"
                    sx={{ backgroundColor: 'white', borderRadius: 1, flex: 1 }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'rgba(240, 83, 28, 1)',
                      color: 'white',
                      textTransform: 'none',
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>

              {/* Resumen de precios */}
              <Box>
                <Typography
                  variant="body1"
                  sx={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}
                >
                  Delivery Fee <b>€{order[0].priceDetails.deliveryFee}</b>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}
                >
                  Discount  <b>€{order[0].priceDetails.discountAmount}</b>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}
                >
                  IVA (21%) <b>€{order[0].priceDetails.iva}</b>
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}
                >
                  Total <b>€{(
                    order[0].priceDetails.deliveryFee +
                    order[0].priceDetails.discountAmount +
                    order[0].priceDetails.iva
                  ).toFixed(2)}</b>
                  €{cartItems.reduce((total, item) => total + (item.sellPrice * item.quantity + 25 + 706.65), 0).toFixed(2)} {/* Sumamos los valores de delivery, discount e iva */}
                </Typography>
              </Box>
            </Box>
          </Box>

            {/* Información de la orden 
            <Box>
              <Typography variant="body1" sx={{ mb: 1 }}>
                Order created <span style={{ color: 'black', fontWeight: '600' }}>{formatDate(order[0].createdAt)}</span>
              </Typography>
              <Typography variant="body1">
                Payment status: <span style={{ color: 'black', fontWeight: '600' }}>{order[0].status}</span>
              </Typography>
              <Typography variant="body1">Payment Method: <b>{order[0].paymentMethod}</b></Typography>
              <Typography variant="body1">
                Notas del cliente: <span style={{ color: '#444' }}>{order[0].customerNotes}</span>
              </Typography>
              <Typography variant="body1">
                Plazo de entrega: <span style={{ color: 'black', fontWeight: '600' }}>{formatDate(order[0].deliveryDeadline)}</span>
              </Typography>
              <Typography variant="body1">Dirección de entrega: {order[0].deliveryAddress}</Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Request by <b style={{ color: 'rgba(240, 83, 28, 1)' }}>{order[0].requesterName}</b> from{' '}
                <b style={{ color: 'rgba(240, 83, 28, 1)' }}>{order[0].requester}</b>
              </Typography>
            </Box>
              */}
            {/* Resumen de precios y descuento 
            <Box
              sx={{
                marginTop: isSmallScreen ? 0 : '-60px',
                padding: isSmallScreen ? 2 : '60px 50px 50px !important',
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: 1,
                flex: isSmallScreen ? 1 : 0.5,
              }}
            >
              <Box
                sx={{
                  marginTop: isSmallScreen ? 0 : '-20px',
                  backgroundColor: '#E2DAF5',
                  borderRadius: 1,
                  p: 2,
                  mb: 2,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    placeholder="Discount %"
                    variant="outlined"
                    size="small"
                    sx={{ backgroundColor: 'white', borderRadius: 1, flex: 1 }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'rgba(240, 83, 28, 1)',
                      color: 'white',
                      textTransform: 'none',
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
              */}
              {/* Resumen de precios 
              <Box>
                <Typography
                  variant="body1"
                  sx={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}
                >
                  Delivery Fee <b>€{order[0].priceDetails.deliveryFee}</b>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}
                >
                  Discount  <b>€{order[0].priceDetails.discountAmount}</b>
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between' }}
                >
                  IVA (21%) <b>€{order[0].priceDetails.iva}</b>
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}
                >
                  Total <b>€{(
                    order[0].priceDetails.deliveryFee +
                    order[0].priceDetails.discountAmount +
                    order[0].priceDetails.iva
                  ).toFixed(2)}</b> {/* Sumamos los valores de delivery, discount e iva 
                </Typography>
              </Box>
            </Box>
          </Box>
          */}
          {/* Botones de acción de lado supplier
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              justifyContent: isSmallScreen ? 'center' : 'space-between',
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                borderColor: 'black',
                backgroundColor: '#FFFF',
                color: 'black',
                '&:hover': { backgroundColor: '#f8f6f1', borderColor: 'black' },
              }}
              onClick={handleRejectOpen}
            >
              Reject Order
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'rgba(240, 83, 28, 1)',
                color: 'white',
              }}
              onClick={handleConfirmOpen}
            >
              Confirm Order
            </Button>
          </Box>
            */}
          {/* Botones de acción */}
          <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: 'flex-end', // Alinea los elementos a la derecha
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'rgba(240, 83, 28, 1)',
              color: 'white',
              display: 'flex', // Asegura la alineación del contenido del botón
              alignItems: 'center',
              gap: 1, // Espacio entre el ícono y el texto
              padding: '12px 24px', // Aumenta el padding (alto y ancho del botón)
              fontSize: '1.1rem', // Tamaño del texto
            }}
            onClick={handleConfirmOpen}
          >
            Pay
            <Wallet style={{ fontSize: '1.5rem' }} /> {/* Tamaño del ícono ajustado */}
          </Button>
        </Box>
          {/* Pop-up de rechazo */}
          <Dialog open={openReject} onClose={handleRejectClose}>
            <DialogTitle>Advertencia</DialogTitle>
            <DialogContent>
              <Typography>
                ¿Estás seguro de que deseas rechazar esta orden?{' '}
                <span style={{ color: 'red', fontWeight: 'bold' }}>No se puede deshacer esta opción.</span>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRejectClose} color="inherit">
                Cancelar
              </Button>
              <Button onClick={handleRejectClose} color="error">
                Rechazar
              </Button>
            </DialogActions>
          </Dialog>

 {/* Confirm Dialog */}
 <Dialog open={openConfirm} onClose={handleConfirmClose}>
            {showConfetti && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 9999,
                  overflow: 'hidden',
                }}
              >
                <Confetti width={window.innerWidth} height={window.innerHeight} />
              </Box>
            )}
            <DialogTitle sx={{ fontSize: '30px', fontWeight: '700' }}>
              <TickCircle color="rgba(132, 192, 63, 1)" /> Thanks for the order
            </DialogTitle>
            <DialogContent>
              <Typography>Your order was completed successfully.</Typography>
              <Typography sx={{ marginTop: '20px' }}>
                <span style={{ color: 'rgba(240, 83, 28, 1)', fontWeight: 'bold' }}>
                  Order Request #{order[0].orderId}
                </span>
              </Typography>
              <Typography sx={{ marginTop: '10px' }}>
                Order created {formatDate(order[0].createdAt)}
              </Typography>
              <Typography sx={{ marginTop: '10px' }}>
                Delayed Payment: Yes (3 months)
              </Typography>
              <Typography sx={{ marginTop: '10px' }}>Payment Method: {order[0].paymentMethod}</Typography>
              <Typography sx={{ marginTop: '10px' }}>Notas del cliente: {order[0].customerNotes}</Typography>
              <Typography sx={{ marginTop: '10px' }}>
                Plazo de entrega: {formatDate(order[0].deliveryDeadline)}
              </Typography>
              <Typography sx={{ marginTop: '10px' }}>Dirección de entrega: {order[0].deliveryAddress}</Typography>
              <Typography sx={{ marginTop: '10px' }}>
                Request by{' '}
                <span style={{ color: 'rgba(240, 83, 28, 1)', fontWeight: '500' }}>
                  {order[0].requesterName}
                </span>{' '}
                from{' '}
                <span style={{ color: 'rgba(240, 83, 28, 1)', fontWeight: '500' }}>
                  {order[0].requester}
                </span>
              </Typography>
              <Typography sx={{ fontSize: '18px', marginTop: '20px' }}>
                We have sent detailed information about the order confirmation to{' '}
                <span style={{ color: 'rgba(240, 83, 28, 1)', fontWeight: '500' }}>
                  {order[0].requester}
                </span>.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button style={{ zIndex: 9999 }} onClick={handleConfirmClose} variant="contained" color="inherit">
                Done
              </Button>
            </DialogActions>
          </Dialog>

        </>
      ) : (
        <Typography variant="h6" color="textSecondary" sx={{ p: 3 }}>
          No orders available or loading...
        </Typography>
      )}
    </Box>
  );
};
export default OrderRequest;