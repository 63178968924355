// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle, I24Support, Driving, User, ArrowRight3, Shop, Home3, Calendar, Diagram, People, Card, Receipt1, Receipt, Box, Setting, MagicStar, Subtitle } from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  profile: User,
  ArrowRight3:Home3,
  Shop:Shop


};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const Supplier = {
  id: 'Supplier',
  title: <FormattedMessage id="
Supplier Management" />,
  type: 'group',
  children: [
   
            {
              id: 'menu-principal',
              title: <FormattedMessage id="Dashboard" />,
              type: 'item',
              url: '/menu-principal',
              icon: icons.ArrowRight3,
              disabled: true
        
            },
        
            {
              id: 'profile',
              title: <FormattedMessage id="Profile" />,
              type: 'item',
              url: '/perfil/salesReport',
              icon: icons.profile
        
            },
        
        
            {
              id: 'perfilll2',
              title: <FormattedMessage id="Calendar" />,
              type: 'item',
              url: '/calendar',
              icon: Calendar,
              disabled: true
            },
        
            {
              id: 'perfilii',
              title: <FormattedMessage id="Analytics" />,
              type: 'item',
              url: '/analytics',
              icon: Diagram,
            },
        
            {
              id: 'peerfil',
              title: <FormattedMessage id="Contacts" />,
              type: 'item',
              url: '/contactos',
              icon: People,
              disabled: false,
              disabled: true
            },
        
            {
              id: 'perfl',
              title: <FormattedMessage id="Payment" />,
              type: 'item',
              url: '/payments',
              icon: Card,
              disabled: true
            },
        
            {
              id: 'perfilll',
              title: <FormattedMessage id="Order History" />,
              subtitle: <FormattedMessage id="Visualize key metrics and streamline your workflow.e" />,
              type: 'item',
              url: '/order-history',
              icon: Receipt1,
              disabled: true
        
            },
        
            {
              id: 'pehrfiiiill',
              title: <FormattedMessage id="Catalog" />,
              type: 'item',
              url: '/catalog',
              icon: Box,
              disabled: true
            },
        
            {
              id: 'perfill',
              title: <FormattedMessage id="Inventory" />,
              type: 'item',
              url: '/inventory',
              icon: Box,
              disabled: false
            },
        
            {
              id: 'perfillllll',
              title: <FormattedMessage id="AI Assistant" />,
              type: 'item',
              url: '',
              icon: MagicStar,
              disabled: true
            },
        
            {
              id: 'perfillllll',
              title: <FormattedMessage id="Settings" />,
              type: 'item',
              url: '/settings',
              icon: Setting,
              disabled: true
            },
  ] 
};

export default Supplier;
