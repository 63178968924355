import { useState } from 'react';
import { Stack, Box, Card, Typography } from '@mui/material';
import OrderHistoryTable from '../../sections/apps/orderHistory/OrderHistoryTable'; 
import CalendarSection from '../../sections/apps/orderHistory/calendar'; 

const OrderHistory = () => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <Card sx={{ p: 2 }}>
      <Stack spacing={3}>
        {/* Breadcrumbs (Dashboard / Order History)
        <Box>
          <Typography variant="body2" color="text.secondary" fontWeight="medium">
            <span style={{ fontWeight: 'normal' }}>Dashboard / </span>
            <span style={{ fontWeight: 'bold', color:"black" }}>Order History</span>
          </Typography>
        </Box> */}
        {/* Título principal y subtítulo */}
        <Box sx={{ marginTop: "4px !important" }}>
          <Typography variant="h4" fontWeight="800" fontSize={"40px"}>
            Order History
          </Typography>
          <Typography variant="subtitle1" color="black" fontWeight={"400"} fontSize={"16"} >
            Visualize key metrics and streamline your workflow.
          </Typography>
        </Box>

        {/* Table and Calendar Section in Flexbox */}
        <Box display="flex" justifyContent="space-between" gap={3}>
          {/* Table Section */}
          <Box sx={{ flex: 1 }}>
            <OrderHistoryTable globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
          </Box>

          {/* Calendar Section */}
          <Box sx={{ width: '300px' }}>
            <CalendarSection calendarDate={calendarDate} setCalendarDate={setCalendarDate} />
          </Box>
        </Box>
      </Stack>
    </Card>
  );
};

export default OrderHistory;