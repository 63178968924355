import PropTypes from 'prop-types';
import { useMemo, useEffect, Fragment, useState, useRef } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import {
  Box,
  Chip,
  LinearProgress,
  Tabs,
  Tab,
  Grid,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  Tooltip
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

// third-party
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

// project-imports
import Loader from 'components/Loader';
import ScrollX from 'components/ScrollX';
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import InvoiceCard from 'components/cards/invoice/InvoiceCard';
import InvoiceChart from 'components/cards/invoice/InvoiceChart';
import { CSVExport, HeaderSort, IndeterminateCheckbox, TablePagination, TableRowSelection } from 'components/third-party/ReactTable';
import AlertColumnDelete from 'sections/apps/kanban/Board/AlertColumnDelete';

import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { alertPopupToggle, getInvoiceDelete, getInvoiceList } from 'store/reducers/invoice';
import { renderFilterTypes, GlobalFilter, DateColumnFilter } from 'utils/react-table';

// assets
import { Edit, Eye, InfoCircle, ProfileTick, Trash } from 'iconsax-react';
import { APICall } from 'api';
import { saveUsers } from 'store/reducers/users';
import { red } from '@mui/material/colors';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const defaultColumn = useMemo(() => ({ Filter: DateColumnFilter }), []);
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const initialState = useMemo(
    () => ({
      filters: [{ id: 'status', value: '' }],
      hiddenColumns: ['avatar', 'email',"gmail","name","nomeArtistico","cpf","cnpj","endereco"],
      pageIndex: 0,
      pageSize: 5
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, selectedRowIds, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn,
      initialState
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const componentRef = useRef(null);

  // ================ Tab ================

  const groups = ['todos',"artista","admin","editora"];
  const countGroup = data.map((item) => item.status);
  const counts = countGroup.reduce(
    (acc, value) => ({
      ...acc,
      [value]: (acc[value] || 0) + 1
    }),
    {}
  );

  const [activeTab, setActiveTab] = useState(groups[0]);

  useEffect(() => {
   setFilter('role', activeTab === 'todos' ? '' : activeTab);
   
  }, [activeTab]);

  return (
    <>
      <Box sx={{ p: 3, pb: 0, width: '100%' }}>
        <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          {groups.map((role, index) => (
            <Tab
              key={index}
              label={role}
              value={role}
              icon={
                <Chip
                  label={
                    role === 'todos'
                      ? data.length
                      : role === 'admin'
                      ? data.filter(u=>u.role=="admin").length
                      : role === 'editora'
                      ?  data.filter(u=>u.role=="editora").length
                      :  data.filter(u=>u.role=="artista").length
                  }
                  color={'success'}
                  variant="light"
                  size="small"
                />
              }
              iconPosition="end"
            />
          ))}
        </Tabs>
      </Box>
      <TableRowSelection selected={Object.keys(selectedRowIds).length} />
      <Stack direction={matchDownSM ? 'column' : 'row'} spacing={1} justifyContent="space-between" alignItems="center" sx={{ p: 3, pb: 3 }}>
        <Stack direction={matchDownSM ? 'column' : 'row'} spacing={2}>
          <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        </Stack>
        <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={matchDownSM ? 1 : 2}>
          {/* <>
            {headerGroups.map((group) => (
              <Stack key={group} direction={matchDownSM ? 'column' : 'row'} spacing={2} {...group.getHeaderGroupProps()}>
                {group.headers.map(
                  (column) =>
                    column.canFilter && (
                      <Box key={column} {...column.getHeaderProps([{ className: column.className }])}>
                        {column.render('Filter')}
                      </Box>
                    )
                )}
              </Stack>
            ))}
          </> */}
          <CSVExport data={data} filename={'user-list.csv'} />
        </Stack>
      </Stack>
      <Box ref={componentRef}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                {headerGroup.headers.map((column) => (
                  <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Fragment key={i}>
                  <TableRow
                    {...row.getRowProps()}
                    onClick={() => {
                      row.toggleRowSelected();
                    }}
                    sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                  >
                    {row.cells.map((cell) => (
                      <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                </Fragment>
              );
            })}
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} initialPageSize={25}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array
};

// ==============================|| INVOICE - LIST ||============================== //

const UserList = () => {
  const [loading, setLoading] = useState(true);
  const { lists, alertPopup } = useSelector((state) => state);
  const [uList, setUList] = useState([]);

  useEffect(() => {
   // dispatch(getInvoiceList()).then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(getInvoiceList())
    APICall.getAllUsersAdmin().then((res) => {
      setUList(res.docs);
      setLoading(false)
    } );
    
  }, []);

  const [list, setList] = useState([]);
  const [invoiceId, setInvoiceId] = useState(0);
  const [getInvoiceId, setGetInvoiceId] = useState(0);

  useEffect(() => {
    console.log(lists)
    setList(lists);
  }, [lists]);

  const navigation = useNavigate();
  const handleClose = (status) => {
    if (status) {
      dispatch(getInvoiceDelete(invoiceId));
      dispatch(
        openSnackbar({
          open: true,
          message: 'Column deleted successfully',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
    }
    dispatch(
      alertPopupToggle({
        alertToggle: false
      })
    );
  };

  const columns = useMemo(
    () => [
    
      {
        Header: 'Nome',
        accessor: 'name',
       
        disableFilters: true
      }, 
       {
        Header: 'cpf',
        accessor: 'cpf',
       
      },
      {
        Header: 'cnpj',
        accessor: 'cnpj',
       
      },
      {
        Header: 'nomeArtistico',
        accessor: 'nomeArtistico',
       
      },     {
        Header: 'endereco',
        accessor: 'endereco',
       
      },
      
   
   
      {
        Header: 'Email',
        accessor: 'picture',
        disableFilters: true,
        Cell: ({ row }) => {
  console.log(row.values)
          const { values } = row;
          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Avatar alt="Avatar" size="sm" src={values.picture || avatarImage(`./avatar-${!values.avatar ? 1 : values.avatar}.png`)} />
              <Stack spacing={0}>
                <Typography variant="subtitle1">{values.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {values.email || values.gmail}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      },
     
      {
        Header: 'Entrou dia',
        accessor: 'createdAt',
        Cell: ({ row }) => {
          console.log(row.values)
                  const { values } = row;
                  return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <Stack spacing={0}>
                        <Typography variant="subtitle1">{new Date(values.createdAt).toLocaleDateString("pt-Br")}</Typography>
                      
                      </Stack>
                    </Stack>
                  );
                }
      },
   
      {
        Header: 'Completou cadastro',
        accessor: 'hasCompletedRegistration',
        Cell: ({ row }) => {
          console.log(row.values)
                  const { values } = row;
                  return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <Stack spacing={0}>
                        <Typography variant="subtitle1">{values.hasCompletedRegistration?values.cpf||values.cnpj:"Não"}</Typography>
                        <Typography variant="caption" color="textSecondary">
                  {values.nomeArtistico }
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {values.endereco }
                </Typography>
                      </Stack>
                    </Stack>
                  );
                }
  
      },
      {
        Header: 'Permissão',
        accessor: 'role',
        Cell: ({ row }) => {
          console.log(row.values)
                  const { values } = row;
                  return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <Stack spacing={0}>
                        <Typography variant="subtitle1" color={values.role=="admin"?"red":"black"}>{values.role.toUpperCase()}</Typography>
                      
                      </Stack>
                    </Stack>
                  );
                }
  
      },
      {
        Header: 'Email',
        accessor: 'email',
  
      },
  
      {
        Header: 'gmail',
        accessor: 'gmail',
  
      },
      {
        Header: 'Ações',
        className: 'cell-center',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title="Ver">
                <IconButton
                disabled
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    // navigation(`/apps/invoice/details/${row.values.id}`);
                  }}
                >
                  <Eye />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar">
                <IconButton
                disabled
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                  //  navigation(`/apps/invoice/edit/${row.values.id}`);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Deletar">
                <IconButton
                disabled
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    //setInvoiceId(row.values.id);
                    // setGetInvoiceId(row.values.invoice_id); // row.original
                    // dispatch(
                    //   alertPopupToggle({
                    //     alertToggle: true
                    //   })
                    // );
                  }}
                >
                  <Trash />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const widgetsData = [
    // {
    //   title: 'Pagamento',
    //   count: 'R$ 18.250',
    //   percentage: 70.5,
    //   isLoss: false,
    //   invoiceCount: '25',
    //   color: theme.palette.success,
    //   chartData: [200, 600, 100, 400, 300, 400, 50]
    // },
  
    // {
    //   title: 'Atraso',
    //   count: 'R$ 3.507',
    //   percentage: 27.4,
    //   isLoss: true,
    //   invoiceCount: '11',
    //   color: theme.palette.error,
    //   chartData: [100, 550, 200, 300, 100, 200, 300]
    // }
  ];

  if (loading) return <Loader />;

  return (
    <>
      <Grid container direction={matchDownSM ? 'column' : 'row'} spacing={2} sx={{ pb: 2 }}>
        <Grid item md={12}>
          <Grid container direction="row" spacing={2}>
            {widgetsData.map((widget, index) => (
              <Grid item sm={6} xs={6} lg={6} key={index}>
                <MainCard>
                  <InvoiceCard
                    title={widget.title}
                    count={widget.count}
                    percentage={widget.percentage}
                    isLoss={widget.isLoss}
                    invoice={widget.invoice}
                    color={widget.color.main}
                  >
                    <InvoiceChart color={widget.color} data={widget.chartData} />
                  </InvoiceCard>
                </MainCard>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* <Grid item md={4} sm={12} xs={12}>
          <Box
            sx={{
              background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
              borderRadius: 1,
              p: 1.75
            }}
          >
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between" spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Avatar alt="Natacha" variant="rounded" type="filled">
                  <ProfileTick style={{ fontSize: '20px' }} />
                </Avatar>
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body1" color="white">
                      Total Recievables
                    </Typography>
                    <InfoCircle color={theme.palette.background.paper} />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="body2" color="white">
                      Current
                    </Typography>
                    <Typography variant="body1" color="white">
                      109.1k
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="body2" color="white">
                  Overdue
                </Typography>
                <Typography variant="body1" color="white">
                  62k
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="h4" color="white" sx={{ pt: 2, pb: 1, zIndex: 1 }}>
              $43,078
            </Typography>
            <Box sx={{ maxWidth: '100%' }}>
              <LinearWithLabel value={90} />
            </Box>
          </Box>
        </Grid> */}
      </Grid>

      <MainCard content={false}>
        <ScrollX>
          <ReactTable columns={columns} data={uList} />
        </ScrollX>
      </MainCard>
      <AlertColumnDelete title={`${getInvoiceId}`} open={alertPopup} handleClose={handleClose} />
    </>
  );
};

UserList.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  email: PropTypes.string,
  avatar: PropTypes.node,
  customer_name: PropTypes.string,
  invoice_id: PropTypes.string,
  id: PropTypes.number,
  value: PropTypes.object,
  toggleRowExpanded: PropTypes.func,
  isExpanded: PropTypes.bool,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  getToggleRowSelectedProps: PropTypes.func
};

function LinearWithLabel({ value, ...others }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress color="warning" variant="determinate" value={value} {...others} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearWithLabel.propTypes = {
  value: PropTypes.number,
  others: PropTypes.any
};

export default UserList;
