import React, { useState } from 'react';
import {
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
  Switch,
  FormControl,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  Stack,
} from '@mui/material';

import AccesosRoles from 'sections/apps/settings/Roles';
const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [orderValue, setOrderValue] = useState(499);
  const [sameDayDelivery, setSameDayDelivery] = useState(false);
  const [sameDayOrderValue, setSameDayOrderValue] = useState(2000);
  const [deferredPayments, setDeferredPayments] = useState(false);
  const [paymentTerm, setPaymentTerm] = useState(30);
  const [deliveryArea, setDeliveryArea] = useState('+20km');
  const [sameDayDeliveryArea, setSameDayDeliveryArea] = useState('0-10km');
  const [receiveSms, setReceiveSms] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [receiveSalesSummary, setReceiveSalesSummary] = useState(false);
  const [frequency, setFrequency] = useState('mensual');

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSave = () => {
    console.log({
      orderValue,
      sameDayDelivery,
      sameDayOrderValue,
      deferredPayments,
      paymentTerm,
      deliveryArea,
      sameDayDeliveryArea,
    });
  };

  return (
    <Box sx={{ p: 3 }} >
      <Typography sx={{fontSize:"34px", fontWeight:"800"}} variant="h4" gutterBottom >
        Preferencias
      </Typography>
      <Typography variant="body2" sx={{width:"700px"}} gutterBottom >
          Permite configurar todos los aspectos clave relacionados con los pedidos, como los horarios de atención, términos de entrega, valor mínimo, opciones de pago aplazado, cobertura geográfica y disponibilidad de entregas en el mismo día.
        </Typography>
      <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Preferencias de pedidos" />
        <Tab label="Comunicaciones y notificaciones" />
        <Tab label="Accesos y Roles de usuario" />
      </Tabs>

      {tabIndex === 0 && (
        <Box>
          <Stack spacing={3}>
          <FormControl fullWidth>
            <FormLabel >¿Cuál es el plazo máximo en que ofreces dichos pagos?</FormLabel>
            <TextField 
              value={orderValue}
              onChange={(e) => setOrderValue(e.target.value)}
              type="number"
              InputProps={{ startAdornment: <Typography>€</Typography> }}
              fullWidth
              sx={{ width: '400px' }} 
              InputLabelProps={{
              style: { fontSize: '20px', top: '-10px', background:"none" }, 
              shrink: true 
              }}
           />
          </FormControl>

            <FormControl>
              <FormLabel>¿Ofreces repartos de pedidos realizados el mismo día?</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={sameDayDelivery}
                    onChange={(e) => setSameDayDelivery(e.target.checked)}
                  />
                }
                label={sameDayDelivery ? 'Sí' : 'No'}
                
              />
            </FormControl>
            {sameDayDelivery && (
              <FormControl fullWidth>
                <FormLabel >¿Cuál es el valor mínimo de una orden para realizar repartos el mismo día?</FormLabel>
             
                <TextField
                  value={sameDayOrderValue}
                  onChange={(e) => setSameDayOrderValue(e.target.value)}
                  type="number"
                  InputProps={{ startAdornment: <Typography>€</Typography> }}
                  fullWidth
                  sx={{ width: '400px' }} 
                  InputLabelProps={{
                  style: { fontSize: '20px' } 
                  }}
                />
              </FormControl>
              )}


            

            <FormControl>
              <FormLabel>¿Aceptas pagos diferidos por parte de los bares?</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    checked={deferredPayments}
                    onChange={(e) => setDeferredPayments(e.target.checked)}
                  />
                }
                label={deferredPayments ? 'Sí' : 'No'}
              />
            </FormControl>

            {deferredPayments && (
              <FormControl fullWidth>
                <FormLabel >¿Cuál es el plazo máximo en que ofreces dichos pagos?</FormLabel>
                <Select
                  sx={{ width: '400px' }} 
                  value={paymentTerm}
                  onChange={(e) => setPaymentTerm(e.target.value)}
                >
                  {[15, 30, 60, 90].map((days) => (
                    <MenuItem key={days} value={days}>
                      {days} días
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            
            <FormControl fullWidth>
              <FormLabel >¿Cuál es el área máxima de entrega a la que realizas entregas?</FormLabel>
              <TextField
              value={deliveryArea}
              onChange={(e) => setDeliveryArea(e.target.value)}
              fullWidth
              sx={{ width: '400px' }} 
              InputLabelProps={{
                style: { fontSize: '20px' } 
              }}
            />
            </FormControl>
            
            <FormControl fullWidth>
              <FormLabel >¿Cuál es el área máxima de entrega a la que realizas entregas?</FormLabel>
              <TextField
              value={sameDayDeliveryArea}
              onChange={(e) => setSameDayDeliveryArea(e.target.value)}
              fullWidth
              sx={{ width: '400px' }} 
              InputLabelProps={{
                style: { fontSize: '20px' } 
              }}
            />
            </FormControl>
            
          </Stack>

          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button variant="outlined" color="inherit">
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
        
        {tabIndex === 1 && (
        <Box>
          <Stack spacing={3}>
            <FormControl>
              <FormLabel>¿Deseas recibir notificaciones por SMS?</FormLabel>
              <FormControlLabel
                control={<Switch checked={receiveSms} onChange={(e) => setReceiveSms(e.target.checked)} />}
                label={receiveSms ? 'Sí' : 'No'}
              />
            </FormControl>

            <FormControl>
              <FormLabel>¿Deseas recibir comunicaciones y promociones por correo electrónico?</FormLabel>
              <FormControlLabel
                control={<Switch checked={receiveEmail} onChange={(e) => setReceiveEmail(e.target.checked)} />}
                label={receiveEmail ? 'Sí' : 'No'}
              />
            </FormControl>

            <FormControl>
              <FormLabel>¿Te gustaría recibir un resumen de tus ventas cada cierto tiempo?</FormLabel>
              <FormControlLabel
                control={<Switch checked={receiveSalesSummary} onChange={(e) => setReceiveSalesSummary(e.target.checked)} />}
                label={receiveSalesSummary ? 'Sí' : 'No'}
              />
            </FormControl>

            {receiveSalesSummary && (
              <FormControl fullWidth>
                <FormLabel>Si la respuesta es sí, por favor elige la frecuencia con que te gustaría recibirlos.</FormLabel>
                <Select
                  sx={{ width: '400px' }}
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                >
                  <MenuItem value="mensual">Mensual</MenuItem>
                  <MenuItem value="semanal">Semanal</MenuItem>
                  <MenuItem value="diario">Diario</MenuItem>
                </Select>
              </FormControl>
            )}
          </Stack>

          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button variant="outlined" color="inherit">
              Cancelar
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
       {tabIndex === 2 && (<AccesosRoles/>)}
    </Box>
    
    
  );
};


export default Settings;
