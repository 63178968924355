import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import IconButton from 'components/@extended/IconButton';


// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  Dialog,
  Stack,
  Table,
  Tooltip,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Box
} from '@mui/material';

// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

import { PopupTransition } from 'components/@extended/Transitions';
import {
  HeaderSort,
  SortingSelect,
  IndeterminateCheckbox,
  TablePagination,
  TableRowSelection
} from 'components/third-party/ReactTable';

import makeData from 'data/react-table';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';
import { Add, Copy, Danger, Edit, Eye, Message, Trash, User } from 'iconsax-react';

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: 'name', desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    allColumns,
    visibleColumns,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 14, hiddenColumns: [], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (matchDownSM) {
      setHiddenColumns(['bar', 'date', 'status']);
    } else {
      setHiddenColumns([]);
    }
  }, [matchDownSM]);

  return (
    <>
      {/* Encabezado */}
      <Stack spacing={2} sx={{ p: 3, backgroundColor: "#F4F6F8 !important" }}>

        {/* Segunda Línea: Filtros */}
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          {/* Filtro Global */}
          <GlobalFilter sx={{ backgroundColor: "#FFFFFF" }}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />

          {/* Selector de Ordenamiento */}
          <SortingSelect sx={{ backgroundColor: "#FFFF !important" }} sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
        </Stack>
      </Stack>

      {/* Tabla con Espaciado */}
      <Stack spacing={3} sx={{ px: 3, pb: 3, backgroundColor: "#F4F6F8" }}>
        <TableRowSelection selected={Object.keys(selectedRowIds).length} />
        <Table {...getTableProps()} sx={{ borderCollapse: 'separate', borderSpacing: '0px' }}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
          <TableRow key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
            <TableCell key={column.id} {...column.getHeaderProps([{ className: column.className }])}>
              
              <Stack direction="row" alignItems="center">

                <HeaderSort column={column} sort />
              </Stack>
            </TableCell>
            ))}
            <TableCell align="center">
            </TableCell>
          </TableRow>
          ))}
        </TableHead>

          <TableBody sx={{
            px: 3,
            pb: 3,
            border: '15px solid grey', 
            borderRadius: '15px', 
            boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: "#FFFF"
          }} {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();

              return (
                <Fragment key={i}>
                  <TableRow {...row.getRowProps()} sx={{ cursor: 'pointer' }}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id} {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded &&
                    renderRowSubComponent({
                      row,
                      rowProps,
                      visibleColumns,
                      expanded,
                    })
                  }
                </Fragment>
              );
            })}
            <TableRow>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination
                  gotoPage={gotoPage}
                  rows={rows}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  renderRowSubComponent: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
};
// ==============================|| ORDER - LIST ||============================== //
const ProductTable = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [cartItems, setCartItems] = useState([]);


  const removeFromCart = (productId) => {
    setData((prevCart) => {
      const updatedCart = prevCart.filter((item) => item.productId !== productId);
      localStorage.setItem("cart", JSON.stringify(updatedCart)); 
      return updatedCart; 
    });
  };
  const handleRemoveItem = (productId) => {

    removeFromCart(productId);
  };


  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    console.log(savedCart);
    if (savedCart) {

      setData(JSON.parse(savedCart));
    } else {

      fetch("http://localhost:82/api/cart")//este fetch no existe los archivos estan en localStorage
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data.data)) {
            setData(data.data);
           
            localStorage.setData("cart", JSON.stringify(data.data));
          } else {
            console.error("La respuesta no contiene un array válido de items del carrito.");
            setData([]);
          }
        })
        .catch(error => {
          console.error("Error fetching cart items:", error);
          setData([]);
        });
    }
  }, [open]);





  const handleAdd = () => {
    setAdd(!add);
  };

  const handleClose = () => {
    setOpen(!open);
  };
  console.log("estos son los cartItems:", data);
  const columns = useMemo(() => [
    {
      Header: "",
      accessor: "image",
      Cell: ({ row }) => (
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      ),
      disableSortBy: true,
    },
    {
      Header: "Product",
      accessor: "name",
      Cell: ({ row }) => {
        const { name, image } = row.values;
        const imageURL = image
          ? `/images/inventoryImagenes/${image}`
          : "/images/default-image.jpg";
        console.log(`Image URL: ${imageURL}`);
        return (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Box sx={{ width: 40, height: 40, overflow: "hidden" }}>
              <img
                src={imageURL}
                alt={name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </Box>
            <Typography variant="subtitle1">{name}</Typography>
          </Stack>
        );
      },
    },
    {
      Header: "Liquor Type",
      accessor: "category",
    },
    {
      Header: "Units",
      accessor: "units",
    },
    {
      Header: "Volume",
      accessor: "volume",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Price",
      accessor: "sellPrice",
    },
    {
      Header: "Status",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { productId } = row.original; // Obtiene el ID del producto de la fila actual
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Tooltip title="Edit">
              <IconButton color="primary" onClick={handleAdd}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton color="error" onClick={() => handleRemoveItem(productId)}>
                <Trash />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ], [theme]);

  return (
    <MainCard>
      <ReactTable
        columns={columns}
        data={data}
        handleAdd={handleAdd}
        renderRowSubComponent={() => <></>}
      />
    </MainCard>
  );
};

export default ProductTable;