import { useState } from 'react';
import { Stack, Box, Card, Typography } from '@mui/material';

import ProductTable from '../../apps/orderHistory/productTable' 
import OrderRequest from './OrderRequest';

const Order = () => {

  const [globalFilter, setGlobalFilter] = useState('');

  return (
    <Card sx={{ p: 2 }}>
      <Stack spacing={3} >
        {/* Breadcrumbs (Dashboard / Order History) */}
        <Box>
          <Typography variant="body2" color="text.secondary" fontWeight="medium">
            <span style={{ fontWeight: 'normal' }}>Order History / </span>
            <span style={{ fontWeight: 'bold', color:"black" }}>Order #12390234098</span>
          </Typography>
        </Box>

        <Box >
          {/* Table Section */}
          <Box sx={{ flex: 1 }}>
            <OrderRequest/>
          </Box>
        </Box>

        {/* Table and Calendar Section in Flexbox */}
        <Box display="flex" justifyContent="space-between" background-color="#F4F6F8 !important" gap={3}>
          {/* Table Section */}
          <Box sx={{ flex: 1 }}>
            <ProductTable />
          </Box>
        </Box>
      </Stack>
    </Card>
  );
};

export default Order;