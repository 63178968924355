import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Tabs,
  Tab, 
  Select, 
  MenuItem, 
  InputLabel, 
  FormControl,
  TextField,
  Pagination, 
} from "@mui/material";
import { CardContent, Divider } from "@mui/material";
import {
  Avatar,
} from "@mui/material";
import { ArrowRight3, More } from "iconsax-react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Paper from "@mui/material/Paper";
import ReactApexChart from "react-apexcharts";
import widgets from "../extra-pages/data.json";
import { useNavigate } from "react-router-dom";
import { APICall } from 'api';
import useAuth from "hooks/useAuth";
import Loader from "components/Loader";

const Analytics = () => {
  const [activeTab, setActiveTab] = useState(0);
  const productLimit = 5; 
  const { user, init } = useAuth();

  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
   const [globalFilter, setGlobalFilter] = useState("");
   const [predictDays, setPredictDays] = useState(3); 
   const [page, setPage] = useState(1);
   const [categoryFilter, setCategoryFilter] = useState("all");
   const [selectedProduct, setSelectedProduct] = useState(null);
   const [selectedProductEstimate, setSelectedProductEstimate] = useState(null);
   const [isLoading, setisLoading] = useState(false);
   
   
   const startIndex = (page - 1) * productLimit;
   const endIndex = startIndex + productLimit;
   const navigate = useNavigate();
 


  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  const filteredProducts = products.filter((product) => {
    const matchesCategory = categoryFilter === "all" || product.category === categoryFilter;
    const matchesSearch = product.name.toLowerCase().includes(globalFilter.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  useEffect(() => {
    if (selectedProduct) {
      const fetchProduct = async () => {
        console.log(selectedProduct);
  
        // Filter the products from user.salesReport.products based on selectedProduct.forecast (product_name)
        const product = user?.salesReport?.products.find(p => p.product_name === selectedProduct.forecast);
  
        // If product is found, set the sales data for the request
        if (product) {
          const salesData = product.sales_data || []; // Assuming sales_data exists on the product
          setisLoading(true)
          const response = await APICall.getProductEstimate({
            sales_data: salesData,
            future_days: 15,
            product_name: selectedProduct.forecast
          });
          setisLoading(false)
  
          setSelectedProductEstimate(response);
        } else {
          console.log("Product not found in sales report");
        }
      };
  
      fetchProduct();
    }
  }, [selectedProduct, user?.salesReport?.products]);

 useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await APICall.getProductsByUserReport();
          setProducts(response.data); // Ajusta según cómo estructures los datos


          const responseCat = await APICall.getProductCategories();
          setProductCategories(responseCat.data); // Ajusta según cómo estructures los datos

          
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
    
      fetchProducts();
    }, []);
    

  const transactions = [
    {
      status: "Canceled",
      amount: "$199.24",
      date: "Jan 17, 2022",
      color: "error",
    },
    {
      status: "Pending",
      amount: "$199.24",
      date: "Jan 17, 2022",
      color: "warning",
    },
    {
      status: "Pending",
      amount: "$199.24",
      date: "Jan 17, 2022",
      color: "warning",
    },
    {
      status: "Completed",
      amount: "$199.24",
      date: "Jan 17, 2022",
      color: "success",
    },
  ];
  const sumSales = (salesData) => {
    if (!salesData || !Array.isArray(salesData)) return 0;
  
    return salesData.reduce((total, obj) => {
      if (!obj.sales_data || !Array.isArray(obj.sales_data)) return total;
  
      const salesTotal = obj.sales_data.reduce((sum, item) => {
        return sum + (item.sold || 0);
      }, 0);
  
      return total + salesTotal;
    }, 0);
  };
  
  {/*const products = [
    {
      name: "Tequila José Cuervo",
      price: "€12,234",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
    {
      name: "Tequila José Cuervo",
      price: "€11,159",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
    {
      name: "Tequila José Cuervo",
      price: "€9,084",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
    {
      name: "Tequila José Cuervo",
      price: "€12,234",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
  ];

  const productss = [
    {
      name: "Tequila José Cuervo",
      price: "€12,234",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
    {
      name: "Tequila José Cuervo",
      price: "€11,159",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
    {
      name: "Tequila José Cuervo",
      price: "€9,084",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
    {
      name: "Tequila José Cuervo",
      price: "€12,234",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
    {
      name: "Tequila José Cuervo",
      price: "€12,234",
      units: "12,131 unidades vendidas",
      img: "https://via.placeholder.com/50",
    },
  ];
*/}
  const users = [
    {
      name: "Jenny Wilson",
      price: "€12,234",
      address: "Austin",
      subtitle: "Wilson Bar",
      avatar: "https://via.placeholder.com/50/673AB7",
    },
    {
      name: "Devon Lane",
      price: "€11,159",
      address: "New York",
      subtitle: "Vía Láctea",
      avatar: "https://via.placeholder.com/50/26A69A",
    },
    {
      name: "Jane Cooper",
      price: "€9,084",
      address: "Toledo",
      subtitle: "Ponzano 14",
      avatar: "https://via.placeholder.com/50/FFCA28",
    },
    {
      name: "Dianne Russell",
      price: "€12,234",
      address: "Naperville",
      subtitle: "La taberna de Martina",
      avatar: "https://via.placeholder.com/50/EF5350",
    },
    {
      name: "Devon Lane",
      price: "€11,159",
      address: "New York",
      subtitle: "Vía Láctea",
      avatar: "https://via.placeholder.com/50/26A69A",
    },
  ];

  const widget = widgets?.value?.accountBalance;


  if (!widget) {
    return null;
  }


  

  
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {/* 
        {activeTab === 0 && (
          <Typography variant="h3">Temporary Analytics</Typography>
        )}
        {activeTab === 1 && (
          <Typography variant="h3">Customer Analytics</Typography>
        )}
          */}
        {activeTab === 0 && (
          <Typography variant="h3">Sales Optimization </Typography>
        )}
        <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
      
          {user?.salesReport?.fileName ?"Using Data uploadded on "+user?.salesReport?.importedAt+" to create sales estimate.":"You must upload your sales history for estimate generation."}
        </Typography>
        {!user?.salesReport?.fileName &&  <Box textAlign="center" mt={2}>
     
     <Box>
     
       <Button 
         variant="contained" 
         color="primary" 
         href="perfil/salesReport"
       >
         Upload Sales Data
       </Button>
     </Box>

 </Box>}
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
        >
          {/* 
          <Tab label="Temporary Analytics" />
          <Tab label="Customer Analytics" />
          */}
          {user?.salesReport?.fileName &&  <Tab label="Product Analytics" />}
        </Tabs>
      </Grid>
    {user?.salesReport?.fileName &&   <Grid item xs={12}>
        {activeTab === 1 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Total Sales{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      
                      {sumSales(user?.salesReport?.products)}
                    </Typography>
             
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Total Products
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                    {user?.salesReport?.products?.length||0}

                    </Typography>
                   
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Revenue{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      12,426{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "green" }}
                    >
                      {" "}
                      +36%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Total Orders{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      103{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "green" }}
                    >
                      {" "}
                      +36%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#FBFAFE",
                p: 4,
                borderRadius: 1,
                mb: 2,
                border: "1px solid #E9E5F1",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Analytics
                </Typography>
                <Button variant="contained" sx={{ backgroundColor: "#E64A19" }}>
                  View All
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Paper
                    sx={{ p: 2, borderRadius: 1, border: "1px solid #E9E5F1" }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5">Sales Report</Typography>
                    </Box>

                    <div>
                  
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 1,
                      p: 2,
                      mb: 2,
                      border: "1px solid #E9E5F1",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        Profit
                      </Typography>
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        €2,313
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Great Job! Your earnings increased by 15% compared to last
                      month!
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 1,
                      p: 2,
                      mb: 2,
                      border: "1px solid #E9E5F1",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        Expenses
                      </Typography>
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        €2,313
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Great Job! Your earnings increased by 15% compared to last
                      month!
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 1,
                      p: 2,
                      mb: 2,
                      border: "1px solid #E9E5F1",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        Deliveries
                      </Typography>
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        14
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Great Job! Your earnings increased by 15% compared to last
                      month!
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Latest Transactions - Grid 8 */}
                <Grid item xs={12} md={8}>
                  <Box
                    sx={{
                      backgroundColor: "#FBFAFE",
                      p: 2,
                      borderRadius: 1,
                      mb: 2,
                      border: "1px solid #E9E5F1",
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="h6" fontWeight="bold">
                        Latest Transactions
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#E64A19" }}
                      >
                        See All Transactions
                      </Button>
                    </Box>
                    {transactions.map((tx, index) => (
                      <Box key={index} mb={2}>
                        <Divider />
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          py={1}
                        >
                          <Box display="flex" alignItems="center">
                            <Chip
                              label={tx.status}
                              color={tx.color}
                              size="small"
                              sx={{ mr: 2 }}
                            />
                            <Box>
                              <Typography sx={{ fontWeight: "bold" }}>
                                Amex card **** 5666
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Card payment
                              </Typography>
                            </Box>
                          </Box>
                          <Box textAlign="right">
                            <Typography sx={{ fontWeight: "bold" }}>
                              {tx.amount}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {tx.date}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{ color: "primary.main", cursor: "pointer" }}
                          >
                            View order
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>

                {/* Top Products - Grid 4 */}
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      backgroundColor: "#FBFAFE",
                      p: 2,
                      pt: 4,
                      borderRadius: 1,
                      mb: 2,
                      pb: 4,
                      border: "1px solid #E9E5F1",
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" mb={2}>
                      <Typography variant="h6" fontWeight="bold">
                        Top Products
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#E64A19" }}
                      >
                        See All Products
                      </Button>
                    </Box>
                    {products.map((product, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        mb={2}
                      >
                        <img
                          src={product.img}
                          alt={product.name}
                          style={{ width: 50, height: 50, marginRight: 12 }}
                        />
                        <Box flex={1}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {product.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {product.units}
                          </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {product.price}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {activeTab === 2 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Total Sales{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      103{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "green" }}
                    >
                      {" "}
                      +10%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  {/* 
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    New Customers{" "}
                  </Typography>
                  */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      5{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "red" }}
                    >
                      {" "}
                      +14%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Active Customers{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      79
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "green" }}
                    >
                      {" "}
                      +36%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Total Orders{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      103{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "green" }}
                    >
                      {" "}
                      +36%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#FBFAFE",
                p: 4,
                borderRadius: 1,
                mb: 2,
                border: "1px solid #E9E5F1",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Analytics
                </Typography>
                <Button variant="contained" sx={{ backgroundColor: "#E64A19" }}>
                  View All
                </Button>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{backgroundColor: "#FFFFFF", borderRadius: 2}}>
                  {users.map((user, index) => (
                    <Box
                      sx={{p: 1, backgroundColor: '#FFFFFF', mt:1 }}
                    >

                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Avatar
                            src={""}
                            sx={{ width: 50, height: 50, mr: 2 }}
                          />
                          <Box>
                            <Typography variant="body1" fontWeight="bold">
                              {user.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {user.subtitle}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              sx={{ mt: 1 }}
                            >
                              {user.price}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {user.address}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Paper
                    sx={{ p: 2, borderRadius: 1, border: "1px solid #E9E5F1" }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5">Sales Report</Typography>
                    </Box>

                    <div>
                  
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

          </>
        )}
        {activeTab === 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
              <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Total Sales{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      
                      {sumSales(user?.salesReport?.products)}
                    </Typography>
             
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Total Products
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                    {user?.salesReport?.products?.length||0}

                    </Typography>
                   
                  </Box>
                </Box>
              </Grid>
              {/*
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    New Customers{" "}
                  </Typography>
                 
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      5{" "}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "red" }}
                    >
                      {" "}
                      +14%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              */}
              {/*
              <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                <Box
                  sx={{
                    backgroundColor: "#FBFAFE",
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    border: "1px solid #E9E5F1",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                    {" "}
                    Active Customers{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      {" "}
                      79
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{ color: "green" }}
                    >
                      {" "}
                      +36%{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              */}
       
            </Grid>
            
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#FBFAFE",
                p: 4,
                borderRadius: 1,
                mb: 2,
                border: "1px solid #E9E5F1",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography sx={{ mb: 0, fontWeight: "700", fontSize: "20px" }} variant="h6" fontWeight="bold">
                  Analytics
                </Typography>

                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="predict-days-label">Future Prediction </InputLabel>
                  <Select
                    labelId="predict-days-label"
                    value={predictDays}
                    onChange={(e) => setPredictDays(e.target.value)}
                    label="Días a predecir"
                  >
                    <MenuItem value={1}>1 day</MenuItem>
                    <MenuItem value={3}>3 days</MenuItem>
                    <MenuItem value={7}>7 days</MenuItem>
                    <MenuItem value={15}>15 days</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/* Filtro por nombre de producto */}
              

              {/* Filtro por categoría */}
              <Grid sx={{ marginBottom: 3 }}>
                
                  <TextField
                    label="Search Products"
                    variant="outlined"
                    fullWidth
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    sx={{ marginBottom: 5, width: "20%", height: "30%", marginLeft: "0px" }}
                  />
              
                <FormControl variant="outlined" sx={{ ml:2,minWidth: 120 }}>
                  <InputLabel id="category-label">Categoría</InputLabel>
                  <Select
                    labelId="category-label"
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    label="Categoría"
                  >
                     <MenuItem value={"all"}>All Itens</MenuItem>
               { productCategories.map(item=>(
                  <MenuItem value={item}>{item}</MenuItem>
                ))    }
                 
                  </Select>
                </FormControl>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ backgroundColor: "#FFFFFF", borderRadius: 2 }}>
                  {filteredProducts.length > 0 ? (
                    filteredProducts.slice(startIndex, endIndex).map((product, index) => (
                      <Box key={index} 
                      sx={{
                        p: 1,
                        backgroundColor: isLoading ? "#f0f0f0" : "#FFFFFF", // Change background color when loading
                        mt: 1,
                        opacity: isLoading ? 0.5 : 1, // Reduce opacity when loading
                        pointerEvents: isLoading ? 'none' : 'auto', // Disable clicks when loading
                      }}
                      onClick={() => {
                        if (!isLoading) { // Only allow click if not loading
                          setSelectedProductEstimate(null);
                          setSelectedProduct(product);
                        }
                      }}
                      style={{ cursor: isLoading ? "not-allowed" : "pointer" }} // Change cursor to 'not-allowed' when loading
                    
                      >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Avatar
                          src={APICall.getProductImage(product.image)}
                            alt={product.name}
                            sx={{ width: 50, height: 50, mr: 2 }}
                          />
                          <Box>
                            <Typography variant="body1" fontWeight="bold">
                              {product.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {product.units}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography variant="h6" fontWeight="bold" sx={{ mt: 1 }}>
                              {product.price}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {product.address}
                            </Typography>
                            {/* Margen de error */}
                            <Typography variant="body2" color="text.secondary">
                              Margen de error: {product.errorMargin|| 0}%
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                      <Typography variant="body1" color="text.secondary">
                        No se encuentran productos en esta categoría.
                      </Typography>
                    </Box>
                  )}
                  {filteredProducts.length > productLimit && (
                    <Box textAlign="center" mt={2}>
                      <Typography variant="body2" color="text.secondary"></Typography>
                    </Box>
                  )}
                  {filteredProducts.length > 0 && (
                    <Box textAlign="center" mt={2}>
                      <Pagination
                        count={Math.ceil(filteredProducts.length / productLimit)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Box>
                  )}
                </Grid>

              {selectedProductEstimate &&   <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Paper sx={{ p: 2, borderRadius: 1, border: "1px solid #E9E5F1" }}>
                    <Box sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <Typography variant="h5">
                        Sales prediction for next {predictDays} days.
                      </Typography>
                    </Box>

                    <div >
                      <ReactApexChart
                        options={{
    chart: {
      id: 'sales-prediction-chart',
      type: 'line',
      height: 350,
    },
    xaxis: {
      categories: Array.from({ length: selectedProductEstimate.predictions.length }, (_, i) => `Day ${i + 1}`),
      title: {
        text: 'Days'
      }
    },
  
    yaxis: {
      title: {
        text: 'Predicted Sales',
        style: {
          fontSize: '16px',
          padding:4 // Optional: Adjust the size of the Y-axis title
        }
      },
      style: {
        fontSize: '16px',
        padding:4 // Optional: Adjust the size of the Y-axis title
      },
      min: Math.min(...selectedProductEstimate.predictions.slice(0, predictDays)), // Dynamic min value
      max: Math.max(...selectedProductEstimate.predictions.slice(0, predictDays)), // Dynamic max value
      labels: {
        formatter: (value) => value.toFixed(2) // Adjust the number of decimals for display
      }
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    title: {
      text: 'Sales '+selectedProduct.name,
      align: 'center',
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  }}
  series={[{ name: 'Predicted Sales', data: selectedProductEstimate.predictions.slice(0, predictDays).map((prediction, index) => ({ x: new Date(Date.now() + (index + 1) * 86400000).toISOString().split('T')[0], y: prediction })) }]}
                        type={'line'}
                        height={350}
                      />
                    </div>
                  </Paper>
                </Grid>}
                {isLoading && <Loader />}
              </Grid>
            </Grid>
            
          </>
        )}
      </Grid>}
     
    </Grid>
  );
};

export default Analytics;
