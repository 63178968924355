import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Switch from "@mui/material/Switch";
import format from "date-fns/format";
import {Typography, IconButton, TextField, Divider  } from "@mui/material";
import { useState, useEffect } from "react";
import { ArrowLeft, CloseCircle, Trash } from "iconsax-react";

import { Box, Checkbox, Button, ButtonGroup, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";


//import { selectQuickPanelData, selectQuickPanelOpen, toggleQuickPanel } from './quickPanelSlice';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(() => ({
  "& .MuiDrawer-paper": {
    width: 400,
  },
}));

/**
 * The quick panel.
 */
function QuickPanel({ open, handleClick}) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(["notifications"]);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const handlePaymentClick = () => {
    navigate("/order"); 
    handleClick(false);
  };

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    console.log(savedCart);
    if (savedCart) {

      setCartItems(JSON.parse(savedCart));
    } else {

      fetch("http://localhost:82/api/cart")
        .then(response => response.json())
        .then(data => {
          if (Array.isArray(data.data)) {
            setCartItems(data.data);
           
            localStorage.setItem("cart", JSON.stringify(data.data));
          } else {
            console.error("La respuesta no contiene un array válido de items del carrito.");
            setCartItems([]);
          }
        })
        .catch(error => {
          console.error("Error fetching cart items:", error);
          setCartItems([]);
        });
    }
  }, [open]);

  


  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleQuantityChange = (productId, action) => {
    setCartItems((prevItems) =>
      prevItems.map(item => 
      {
        if (item.productId === productId) {
          if (action === "increment") {
            return {...item, quantity: item.quantity + 1 };
          } else if (action === "decrement" && item.quantity > 1) {
            return {...item , quantity: item.quantity - 1}
          }
        }
        return item;
      }
      )
    );
  };

  const removeFromCart = (productId) => {
    setCartItems((prevCart) => {
      const updatedCart = prevCart.filter((item) => item.productId !== productId);
      localStorage.setItem("cart", JSON.stringify(updatedCart)); 
      return updatedCart; 
    });
  };

  const handleRemoveItem = (productId) => {

    removeFromCart(productId);
  };
  console.log(cartItems);
      
  return (
    <StyledSwipeableDrawer open={open} onClose={handleClick} anchor="right">
      <div style={{ display: "flex", justifyContent: "space-between", margin: "10px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <ArrowLeft />
          <Typography variant="h5">Mi Carrito | Licoteca</Typography>
        </div>
        <div onClick={handleClick} style={{ cursor: "pointer" }}>
          <CloseCircle />
        </div>
      </div>

      <Divider />

      <Box sx={{ width: "100%", maxWidth: 480, bgcolor: "background.paper" }}>
        <List>
          {open &&
            cartItems.map((item) => {
              const price = parseFloat(item.price);
              const sellPrice = parseFloat(item.sellPrice);
              let discountPercentage = null;

              if (!isNaN(price) && price > 0 && sellPrice > 0 && price > sellPrice) {
                discountPercentage = ((price - sellPrice) / price) * 100;
              }

              const formattedDiscount = discountPercentage !== null ? `${Math.round(discountPercentage)}%` : null;

              return (
                <ListItem key={item.productId}>
                  <ListItemIcon />
                  <Box sx={{ width: 50, height: 70, marginRight: 2 }}>
                      <img
                        src={`/images/inventoryImagenes/${item.image}`}
                        alt={item.name}
                        style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px" }}
                      />
                    </Box>
                  <ListItemText
                    secondary={
                      <>
                      
                        <Chip
                          label={formattedDiscount || "Sin descuento" }
                          color={formattedDiscount ? "success" : "default" }
                          size="small"
                          style={{ marginRight: "10px" }}
                        />
                         {item.importado && (
                          <Chip
                            label="Importado"
                            color="primary"
                            size="small"
                            style={{
                              backgroundColor: 'rgba(63, 81, 181, 0.1)', 
                              color: 'rgba(63, 81, 181, 1)', 
                              padding: '4px 8px',
                              borderRadius: '8px',
                              fontSize: '12px',
                              fontWeight: '500',
                              marginRight: '10px',
                            }}
                          />
                        )}
                        <Typography variant="h5" color="black">
                          {item.name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {item.details}
                        </Typography>
                        
                        <Divider />
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                        <ButtonGroup>
                          <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #d1d5db',
                                borderRadius: '12px',
                                padding: '0px 0px',
                                backgroundColor: '#f9fafb',
                                width: 'fit-content',
                              }}
                            >
                              <IconButton
                                size="small"
                                onClick={() => handleQuantityChange(item.productId, 'decrement')}
                                style={{
                                  backgroundColor: '#e5e7eb',
                                  borderRadius: '50%',
                                  padding: '2px',
                                  width: '24px',
                                  height: '24px',
                                  fontSize: '12px',
                                }}
                              >
                                -
                              </IconButton>
                              <TextField
                                value={item.quantity}
                                onChange={(e) => handleQuantityChange(item.productId, parseInt(e.target.value) || 0)}
                                inputProps={{
                                  style: { position:"unset !important" ,fontSize: '12px', textAlign: 'center', padding: '0', width: '32px', height: '24px' },
                                }}
                                style={{
                                  width: '40px',
                                  margin: '0 4px',
                                }}
                              />
                              <IconButton
                                size="small"
                                onClick={() => handleQuantityChange(item.productId, 'increment')}
                                style={{
                                  backgroundColor: '#e5e7eb',
                                  borderRadius: '50%',
                                  padding: '2px',
                                  width: '24px',
                                  height: '24px',
                                  fontSize: '12px',
                                }}
                              >
                                +
                              </IconButton>
                              
                            </div>
                            <button
                            onClick={() => handleRemoveItem(item.productId)}
                            style={{
                              backgroundColor: 'white', 
                              color: '#d32f2f', 
                              border: 'none',
                              padding: '4px', 
                              borderRadius: '4px',
                              fontSize: '14px',
                              fontFamily: 'Arial, sans-serif',
                              cursor: 'pointer',
                              transition: 'all 0.3s ease',
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = '#f5f5f5'; 
                              e.target.style.color = 'black'; 
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = 'white'; 
                              e.target.style.color = '#d32f2f'; 
                            }}
                          >
                            <IconButton
                              size="small"
                              style={{
                                color: 'black', 
                              }}
                            >
                              <Trash />
                            </IconButton>
                          </button>
                        </ButtonGroup>
                          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            
                            {price > sellPrice && (
                              <Typography variant="h5" color="text.secondary" style={{ textDecoration: "line-through", fontSize:"12px", fontWeight:"400" }}>
                                €{(price * item.quantity).toFixed(2)}
                              </Typography>
                            )}
                            
                            <Typography variant="h6" color="black" style={{fontSize:"17px"}}>
                              €{(sellPrice * item.quantity).toFixed(2)}
                            </Typography>

                          </div>
                        </Box>
                      </>
                    }
                  />
                </ListItem>
              );
            })}
        </List>

        {/* Footer Section */}
        <Box sx={{ p: 2, m: 2, backgroundColor: "#F5F4FB", borderRadius: "8px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Subtotal</Typography>
            <Typography variant="h6">
              €{cartItems.reduce((total, item) => total + (item.sellPrice * item.quantity), 0).toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">Envío</Typography>
            <Typography variant="h6">€25.00</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">IVA</Typography>
            <Typography variant="h6">€706.65</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" fontWeight="bold">Total</Typography>
            <Typography variant="h6" fontWeight="bold">
              €{cartItems.reduce((total, item) => total + (item.sellPrice * item.quantity + 25 + 706.65), 0).toFixed(2)}
            </Typography>
          </Box>
        </Box>

        {/* Bottom buttons */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, p: 2 }}>
          <Button variant="outlined">Programar pedido</Button>
          <Button onClick={handlePaymentClick} variant="contained" color="primary">
            Proceder al pago
          </Button>
        </Box>
      </Box>
    </StyledSwipeableDrawer>
  );
}


export default QuickPanel;
