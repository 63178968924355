
// project-imports
import { useCart } from '../../hooks/useCart';
import MainCard from "components/MainCard";
import LogoIcon from "components/logo/LogoIcon";
import arrow from "assets/images/arrow.png";
import React, { useState, useEffect } from "react";
import { Card, CardContent,useMediaQuery, Typography, Button, IconButton, TextField, Box, Grid, Stack, Slide, Pagination,Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,  } from "@mui/material";
import { border, borderColor, grid } from "@mui/system";
import usePagination from "hooks/usePagination";
import { GlobalFilter } from "utils/react-table";
import { Edit2, Diagram, Trash, CloseCircle, TickCircle, Add, Pointer } from 'iconsax-react';
import { APICall } from 'api';




// ==============================|| SAMPLE PAGE ||============================== //

const Inventory = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [sortBy, setSortBy] = useState("Default");
  const [globalFilter, setGlobalFilter] = useState("");
  const [add, setAdd] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [userCard, setUserCard] = useState([]);
  const [page, setPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]); 
  const [quantities, setQuantities] = useState({}); 
  const [productQuantities, setProductQuantities] = useState({});
  const [cart, setCart] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openResume, setOpenResume] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [addProducts, setAddProducts] = useState([
    { id: 1, name: "1906 Reserva especial", quantity: 112 },
    { id: 2, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 3, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 4, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 5, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 6, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 7, name: "Cerveza Mahou 12 unidades", quantity: 112 },
    { id: 8, name: "Cerveza Mahou 12 unidades", quantity: 112 },
  ]);
  
  const itemsPerPage = 5;
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  const handleQuantityChange = (id, newQuantity) => {
    setAddProducts((prevAddProducts) =>
      prevAddProducts.map((product) =>
        product.id === id ? { ...product, quantity: newQuantity } : product
      )
    );
  };
  const displayedProducts = addProducts.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  

    const PER_PAGE = 10;
    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await APICall.getProducts();
          setProducts(response.data); // Ajusta según cómo estructures los datos
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
    
      fetchProducts();
    }, []);

    const handleAddProduct = (id) => {
      setAddedProducts((prev) => [...prev, id]); 
      console.log("Producto añadido con ID:", id); 
    };

    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
      const filtered = products.filter(product =>
        product.name.toLowerCase().includes(globalFilter.toLowerCase())
      );
      setFilteredProducts(filtered);
    }, [globalFilter, products]);

    const addToCart = (productId, quantity, name, details, price) => {
      console.log("Producto ID:", productId);  
      console.log("Cantidad:", quantity);
    
      if (quantity <= 0) {
        alert("La cantidad debe ser mayor a 0");
        return;
      }
    

      const productDetails = products.find((product) => product._id === productId);
      if (!productDetails) {
        console.error("Producto no encontrado");
        return; 
      }
    
      setCart((prevCart) => {
        console.log("Carrito anterior:", prevCart);  
    
        const existingProduct = prevCart.find((item) => item.productId === productId);
        console.log("Producto existente:", existingProduct); 
    
        if (existingProduct) {

          const updatedCart = prevCart.map((item) =>
            item.productId === productId
              ? { ...item, quantity: item.quantity + quantity } 
              : item
          );
          console.log("Carrito actualizado con producto existente:", updatedCart);  
          return updatedCart;
        }

        const newCart = [
          ...prevCart,
          {
            productId,
            quantity,
            name: productDetails.name,  
            details: productDetails.details ,
            price:productDetails.price
          }
        ];
        
        
        localStorage.setItem("cart", JSON.stringify(newCart));
        console.log("Carrito actualizado con nuevo producto:", newCart); 
        return newCart;
      });
    

      setProductQuantities((prev) => {
        const newQuantities = { ...prev, [name]: 0 }; 
        return newQuantities;
      });
    };
    const handleDecreaseQuantity = (productId) => {
      setProductQuantities((prev) => {
        const newQuantity = (prev[productId] || 0) > 0 ? prev[productId] - 1 : 0;
        return {
          ...prev,
          [productId]: newQuantity,
        };
      });
    };

    
    const handleViewDetails = (productId) => {
      console.log('Ver detalles del producto con ID:', productId);
    
    };
    
    const handleDeleteProduct = (productId) => {
      console.log('Eliminar producto con ID:', productId);
    
    };

  
    const [product, setProduct] = useState({
      name: "Vino Malbec",
      details: "Tinto | 750ml | 13% Alcohol",
      price: 1200,
      units: 25,
      safetyStock: 5,
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editingProductId, setEditingProductId] = useState(null);

  
    const handleEditProduct = (id) => {
      console.log("Editando producto con ID:", id);
      setIsEditing(true); 
    };

    const handleEditSingleProduct = (id) => {
      console.log("Editando producto con ID:", id);
      const product = products.find((product) => product._id === id);
    
      if (product) {
        setEditingProductId(id); 
        setProductToEdit(product); 
      } else {
        console.error("Producto no encontrado");
      }
    };
  
    const handleClose = () => {
      setIsEditing(false); 
      setOpen(false);
      setOpenSuccess(false);
      setOpenResume(false);
      setEditingProductId(null);
      setProductToEdit(null); 

    };
  
    const handleSave = () => {
      console.log("Producto guardado:", product);
      alert("Producto guardado correctamente");
      setIsEditing(false); 
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClickOpenUpload = () => {
      setOpenUpload(true);
    };
  
    const handleClickOpenResume = () => {
      setOpenResume(true);
      setOpen(false);
      setOpenUpload(false);
    };
  
    const handleSucces = () => {
      setOpen(false);
      setOpenResume(false);
      setOpenUpload(false);
      setOpenSuccess(true);
    };
  

    const count = Math.ceil(products.length / PER_PAGE);
    const _DATA = usePagination(products, PER_PAGE);

    const handleChangePage = (e, p) => {
      setPage(p);
      _DATA.jump(p);
    };
    const paginatedProducts = filteredProducts.slice((page - 1) * PER_PAGE, page * PER_PAGE);

    return (
   <div>
     <div>
     <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12}} style={{ padding: "16px", marginBottom:"70px" }}>
        <Typography variant="h3" sx ={{ml: "16px", fontWeight:"800", fontSize:"40px", marginBottom:"20px" }}>
             Your Inventory
         </Typography>
         <Typography variant="h6" sx={{ml: "16px", mb: "20px" }}>
           This is a general summary of your current inventory. Here you can see the total number of products, the items you have in stock, the best sellers and the least consumed. Use this view to maintain control of your inventory, manage on-hand quantities, and adjust prices to optimize your sales.
         </Typography>
         <Grid item xs={12} sm={6} md={3} >
             <Box  style={{ backgroundColor: "#313A13", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  }}>
                 <Typography variant="h4" mb={2} color={"#FFFFFF"}>Total Products</Typography>
                 <Box
                     sx={{
                        display: "flex",
                       justifyContent: "space-between",
                        alignItems: "center",
                         }}
                   >
                    <Typography variant="h4" color={"#FFFFFF"} fontWeight="bold">
                       {" "}
                        521{" "}
                    </Typography>
                     <Typography
                       variant="h6"
                      fontWeight="bold"
                      sx={{ color: "rgba(132, 192, 63, 1)" }}
                      >
                      {" "}
                      +10%{" "}
                  </Typography>
               </Box>
             </Box>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
             <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                 <Typography variant="h4" mb={2} color={"rgba(17, 18, 23, 1)"}>Of Stock</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    alignItems: "center",
                   }}
                     >
                     <Typography variant="h4" fontWeight="bold">
                       {" "}
                        13{" "}
                     </Typography>
                      <Typography
                         variant="h6"
                           fontWeight="bold"
                           sx={{ color: "red" }}
                       >
                         {" "}
                           +14%{" "}
                      </Typography>
                   </Box>
             </Box>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
             <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                 <Typography variant="h4" mb={2} color={"rgba(17, 18, 23, 1)"}>Best selling products</Typography>
                 
                 <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    alignItems: "center",
                   }}
                     >
                      <Typography variant="h4" fontWeight="bold">
                        {" "}
                          54{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "rgba(132, 192, 63, 1)" }}
                        >
                        {" "}
                        +36% en ventas{" "}
                    </Typography>
                   </Box>
             </Box>
         </Grid>
         <Grid item xs={12} sm={6} md={3}>
             <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                 <Typography variant="h4" mb={2} color={"rgba(17, 18, 23, 1)"}>Least selling products</Typography>
                 <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    alignItems: "center",
                   }}
                     >
                      <Typography variant="h4" fontWeight="bold">
                        {" "}
                          104{" "}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ color: "rgba(132, 192, 63, 1)" }}
                        >
                        {" "}
                        +35% en ventas{" "}
                    </Typography>
                   </Box>
             </Box>
         </Grid>
     </Grid>
     {isEditing && (
      <Grid>
          <Typography variant="h3" sx ={{ml: "16px", fontWeight:"800", fontSize:"40px", marginBottom:"20px" }}>
            Modo edicion
         </Typography>
         <Typography variant="h6" sx={{ml: "16px", mb: "20px" }}>
         Estás editando en el modo de edición general. En este modo activo, puedes modificar tanto las cantidades disponibles en tu inventario como el precio por unidad de cada producto.
         </Typography>
      </Grid>
         )}

{editingProductId && (
      <Grid>
          <Typography variant="h3" sx ={{ml: "16px", fontWeight:"800", fontSize:"40px", marginBottom:"20px" }}>
            Editando un unico producto
         </Typography>
         <Typography variant="h6" sx={{ml: "16px", mb: "20px" }}>
         Add the products you offer to your catalogue here, including all relevant details such as type, brand, and packaging. If you need to make changes to your stock, you can do it directly in your inventories. This will help ensure your inventory is up-to-date and ready for orders.
         </Typography>
      </Grid>
         )}
         <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
        <TextField
          label="Search Products"
          variant="outlined"
          fullWidth
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
          sx={{ marginBottom: 3, width: "20%", height:"20%", marginLeft:"20px" }}
        />
        
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontWeight:"600",
              backgroundColor: "rgba(87, 99, 232, 1)",
              textTransform: "none",
              borderRadius: "10px",
              padding: "8px 20px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(67, 81, 191, 1)", 
              },
            }}
          >
            Optimize with AI
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditProduct}
            sx={{
              backgroundColor: "rgba(240, 83, 28, 1)",
              textTransform: "none",
              borderRadius: "10px",
              padding: "8px 20px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(217, 73, 14, 1)",
              },
            }}
          >
            Edit My Inventory
          </Button>
        </Box>
      </Box>
         
             <Grid container spacing={3} columns={10}>
             {isEditing && (
                <Card 
                 onClick={handleClickOpen}
                  style={{
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    backgroundColor: 'rgba(49, 58, 18, 1)', 
                    color: '#fff',
                    height: '350px',
                    width:"225px",
                    marginLeft:"20px",
                    marginTop:"40px",
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: "pointer"
                  }}
                >
                  <Add size={32}/>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: '700',
                      marginBottom: '8px',
                      textAlign: 'left',
                      fontSize:"26px",
                      width:"152px"
                    }}
                  >
                    Añadir un producto a mi catálogo
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#D1D5DB',
                      textAlign: 'left',
                      marginBottom: '16px',
                      fontSize:"15px"
                    }}
                  >
                    ¿Trabajas con un producto que no está en nuestra base de datos?
                  </Typography>

                </Card>
              )}
             {paginatedProducts.length > 0 ? (
               paginatedProducts.map((product, index) => {
                 
                 const price = parseFloat(product.price);
                 const sellPrice = parseFloat(product.sellPrice); 
           
                 let discountPercentage = null;
                   if (!isNaN(price) && price > 0 && product.sellPrice > 0 && price > sellPrice) {
                     discountPercentage = ((price - product.sellPrice) / price) * 100;
                   }
 
                   
                   const formattedDiscount = discountPercentage !== null ? `${Math.round(discountPercentage)}%` : null;
 
                   
                   const showDiscount = price > sellPrice;
           
                 return (
                   <Slide key={index} direction="up" in={true} timeout={50}>
                     <Grid item xs={10} sm={5} lg={2}>
                        <div style={{marginTop:"20px"}}>
                          {isEditing && (
                            <Card className="w-[400px] max-w-[95vw] relative">
                              {/* Botón de cierre e indicador de estado */}
                              <div
                                className="flex items-center justify-between px-4 pt-4"
                                style={{ alignItems: "center", display:"flex", justifyContent:"space-between", alignContent:"center" }}
                              >
                                {/* Indicador de estado */}
                                <div className="flex items-center gap-2" style={{marginTop:"10px", marginLeft:"10px"}}>
                                  <TickCircle size={"15px"} color="rgba(240, 83, 28, 1)" className="h-5 w-5" style={{marginRight:"5px"}} />
                                  <span className="text-orange-500">importado</span>
                                </div>

                                {/* Botón de cierre */}
                                <button
                                  onClick={handleClose}
                                  style={{
                                    marginTop:"10px",
                                    border: "none",
                                    background: "none",
                                    cursor: "pointer",
                                  }}
                                  className="text-gray-500 hover:text-gray-700"
                                >
                                  <CloseCircle size={"20px"} className="h-5 w-5" />
                                </button>
                              </div>

                              <CardContent className="pt-6">
                                <div className="space-y-6">
                                  {/* Información general del producto */}
                                  <div>
                                    <Typography variant="h5" className="text-2xl font-bold">
                                      {product.name}
                                    </Typography>
                                    <Typography
                                      style={{ color: "rgba(108, 117, 147, 1)", marginBottom: "10px" }}
                                      className="text-gray-500"
                                    >
                                      {product.details}
                                    </Typography>
                                  </div>

                                  {/* Campos editables */}
                                  <div className="space-y-4">
                                    {/* Precio */}
                                    <div>
                                      <TextField
                                        id="price"
                                        type="number"
                                        value={product.price || ""}
                                        onChange={(e) =>
                                          setProduct({
                                            ...product,
                                            price: parseFloat(e.target.value),
                                          })
                                        }
                                        className="text-xl font-semibold"
                                        fullWidth
                                      />
                                      <Typography
                                        style={{
                                          color: "rgba(108, 117, 147, 1)",
                                          fontSize: "12px",
                                          marginBottom: "4px",
                                        }}
                                      >
                                        Unidades en inventario
                                      </Typography>
                                    </div>

                                    {/* Stock de seguridad */}
                                    <div>
                                      <TextField
                                        type="number"
                                        value={product.safetyStock || ""}
                                        onChange={(e) =>
                                          setProduct({
                                            ...product,
                                            safetyStock: parseInt(e.target.value, 10),
                                          })
                                        }
                                        fullWidth
                                      />
                                      <Typography
                                        style={{ color: "rgba(108, 117, 147, 1)", fontSize: "12px" }}
                                        variant="subtitle2"
                                        className="text-gray-500"
                                      >
                                        Stock de seguridad
                                      </Typography>
                                    </div>
                                  </div>

                                  {/* Botón de guardar */}
                                  <Button
                                    style={{
                                      marginTop: "6px",
                                      backgroundColor: "rgba(240, 83, 28, 1)",
                                      color: "rgba(253, 252, 255, 1)",
                                      width: "150px",
                                    }}
                                    className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                                    onClick={handleSave}
                                  >
                                    Guardar
                                  </Button>
                                </div>
                              </CardContent>
                            </Card>
                          )}
                        </div>
                        {editingProductId === product._id && (
                          <div className="absolute top-0 left-0 w-full h-full bg-white z-10">
                            <Card className="w-[400px] max-w-[95vw] relative">
                              {/* Botón de cierre e indicador de estado */}
                              <div
                                className="flex items-center justify-between px-4 pt-4"
                                style={{ alignItems: "center", display:"flex", justifyContent:"space-between", alignContent:"center" }}
                              >
                                {/* Indicador de estado */}
                                <div className="flex items-center gap-2" style={{marginTop:"10px", marginLeft:"10px"}}>
                                  <TickCircle size={"15px"} color="rgba(240, 83, 28, 1)" className="h-5 w-5" style={{marginRight:"5px"}} />
                                  <span className="text-orange-500">importado</span>
                                </div>

                                {/* Botón de cierre */}
                                <button
                                  onClick={handleClose}
                                  style={{
                                    marginTop:"10px",
                                    border: "none",
                                    background: "none",
                                    cursor: "pointer",
                                  }}
                                  className="text-gray-500 hover:text-gray-700"
                                >
                                  <CloseCircle size={"20px"} className="h-5 w-5" />
                                </button>
                              </div>

                              <CardContent className="pt-6">
                                <div className="space-y-6">
                                  {/* Información general del producto */}
                                  <div>
                                    <Typography variant="h5" className="text-2xl font-bold">
                                      {product.name}
                                    </Typography>
                                    <Typography
                                      style={{ color: "rgba(108, 117, 147, 1)", marginBottom: "10px" }}
                                      className="text-gray-500"
                                    >
                                      {product.details.length > 100 ? product.details.substring(0, 100) + '...' : product.details}
                                    </Typography>
                                  </div>

                                  {/* Campos editables */}
                                  <div className="space-y-4">
                                    {/* Precio */}
                                    <div>
                                      <TextField
                                        id="price"
                                        type="number"
                                        value={product.stock || ""} 
                                        onChange={(e) =>
                                          setProduct({
                                            ...product,
                                            price: parseFloat(e.target.value),
                                          })
                                        }
                                        className="text-xl font-semibold"
                                        fullWidth
                                      />
                                      <Typography
                                        style={{
                                          color: "rgba(108, 117, 147, 1)",
                                          fontSize: "12px",
                                          marginBottom: "4px",
                                        }}
                                      >
                                        Unidades en inventario
                                      </Typography>
                                    </div>

                                    {/* Stock de seguridad */}
                                    <div>
                                      <TextField
                                        type="number"
                                        value={product.safetyStock || ""}
                                        onChange={(e) =>
                                          setProduct({
                                            ...product,
                                            safetyStock: parseInt(e.target.value, 10),
                                          })
                                        }
                                        fullWidth
                                      />
                                      <Typography
                                        style={{ color: "rgba(108, 117, 147, 1)", fontSize: "12px" }}
                                        variant="subtitle2"
                                        className="text-gray-500"
                                      >
                                        Stock de seguridad
                                      </Typography>
                                    </div>
                                  </div>

                                  {/* Botón de guardar */}
                                  <Button
                                    style={{
                                      marginTop: "6px",
                                      backgroundColor: "rgba(240, 83, 28, 1)",
                                      color: "rgba(253, 252, 255, 1)",
                                      width: "150px",
                                    }}
                                    className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                                    onClick={handleSave}
                                  >
                                    Guardar
                                  </Button>
                                </div>
                              </CardContent>
                            </Card>
                            </div>
                          )}

                        
                        
                        
{!isEditing && editingProductId !== product._id && (
  <Card
    style={{
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '12px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    {/* Contenido de la tarjeta */}
    <div
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        display: 'flex',
        gap: '8px',
      }}
    >
      <IconButton
        aria-label="edit"
        size="small"
        onClick={() => handleEditSingleProduct(product._id)}
      >
        <Edit2 size="20" color="#1c1c1c" />
      </IconButton>
      <IconButton
        aria-label="view details"
        size="small"
        onClick={() => handleViewDetails(product._id)}
      >
        <Diagram size="20" color="#1c1c1c" />
      </IconButton>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => handleDeleteProduct(product._id)}
      >
        <Trash size="20" color="#1c1c1c" />
      </IconButton>
    </div>

    <div
      style={{
        position: 'absolute',
        top: '8px',
        left: '8px',
        display: 'flex',
        gap: '8px',
      }}
    >
      {product.importado && (
        <span
          style={{
            backgroundColor: 'rgba(63, 81, 181, 0.1)',
            color: 'rgba(87, 99, 232, 1)',
            padding: '4px 8px',
            borderRadius: '8px',
            fontSize: '12px',
            fontWeight: '500',
          }}
        >
          Importado
        </span>
      )}
    </div>

    <img
      src={APICall.getProductImage(product.image)}
      alt={product.name}
      style={{
        width: '100px',
        height: '100px',
        objectFit: 'contain',
        marginBottom: '8px',
        marginTop: '35px',
      }}
    />

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        borderBottom: '1px solid #b1b4b9',
        paddingBottom: '4px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography
          variant="h5"
          color="textPrimary"
          style={{
            fontWeight: '400',
            fontSize: '14px',
            color: 'rgba(108, 117, 147, 1)',
            marginRight: '4px',
          }}
        >
          Inventario
        </Typography>
        <Typography
          variant="h5"
          color="textPrimary"
          style={{
            fontWeight: '700',
            fontSize: '18px',
          }}
        >
          {product.stock} uds.
        </Typography>
      </div>
    </div>

    <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: '5px',
  }}
>
  <Typography
    variant="subtitle1"
    style={{
      fontSize: '15px',
      fontWeight: '700',
      textAlign: 'left',
    }}
  >
    {product.name}
  </Typography>
  <Typography
    variant="body2"
    color="textSecondary"
    style={{
      color: 'rgba(108, 117, 147, 1)',
      textAlign: 'left',
      fontSize: '0.70rem',
    }}
  >
    {product.details.length > 100 ? product.details.substring(0, 100) + '...' : product.details}
  </Typography>
</div>
  </Card>
)}
                     </Grid>
                    
                     
                   </Slide>    
                 );
               })
             ) : (
               <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', width: '100%' }}>
                 No se encontraron productos
               </Typography>
             )}
             
           </Grid>
           {/* Pop up Añadir producto*/}
           <Dialog open={open} onClose={handleClose}>
              <DialogTitle
                sx={{
                  color: "rgba(22, 23, 29, 1)",
                  fontWeight: "700",
                }}
              >
                Añadir producto(s) nuevo
              </DialogTitle>
              <Typography
                variant="body2"
                sx={{ marginTop: 0, marginLeft: 3, color: "rgba(108, 117, 147, 1)", width:"350px" }}
              >
                ¿Necesitas agregar un producto a tu inventario? No hay problema, agrégalo.
              </Typography>
              <Divider sx={{mt:2,mb:2}}/>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                  sx={{
                    width:"320px",
                    border: "1px solid black",
                    backgroundColor: "#fff",
                    color: "black",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                  onClick={handleClickOpenUpload}
                >
                  Importar producto(s) desde archivo
                </Button>
              </DialogActions>
              <DialogActions sx={{ justifyContent: "center", mb:2 }}>
                <Button
                  onClick={handleClickOpenUpload}
                  color="primary"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  Agregar nuevo producto desde base de datos
                </Button>
              </DialogActions>
            </Dialog>
            {/* Pop up Upload producto*/}
                  <Dialog open={openUpload} onClose={handleClose}>
                    <DialogTitle
                      sx={{
                        color: "rgba(22, 23, 29, 1)",
                        fontWeight: "700",
                      }}
                    >
                      Please upload your file
                    </DialogTitle>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 0, marginLeft: 3, color: "rgba(108, 117, 147, 1)" }}
                    >
                      We accept CSV, XSL/XLSX, ODS, TSV, JSON, or PDF formats.
                    </Typography>
                    <DialogTitle
                      sx={{
                        color: "rgba(22, 23, 29, 1)",
                        fontWeight: "700",
                      }}
                    >
                      Import your database
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        type="file"
                        fullWidth
                        variant="outlined"
                      />
                    </DialogContent>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 0, marginLeft: 3, color: "rgba(108, 117, 147, 1)" }}
                    >
                      We accept CSV, XSL/XLSX, ODS, TSV, JSON, or PDF formats.
                    </Typography>
                    <DialogActions sx={{justifyContent:"center",mb:2, mt:2}}>
                      <Button sx={{backgroundColor:"rgba(253, 237, 231, 1)",textTransform: "none",}} onClick={handleClose}>Skip this step</Button>
                      <Button sx={{width:"120px"}} onClick={handleClickOpenResume} color="primary" variant="contained">
                        Next
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* Pop up Resumen de pedido*/}
                  <Dialog open={openResume} onClose={handleClose} >
                    <DialogTitle
                      sx={{
                        color: "rgba(22, 23, 29, 1)",
                        fontWeight: "700",
                      }}
                    >
                      Resumen de productos
                    </DialogTitle>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 0, marginLeft: 4, color: "rgba(108, 117, 147, 1)", width:"400px" }}
                    >
                      Aquí encontrarás un listado de los productos que vas a añadir. Puedes
                      editar las cantidades antes de confirmar los cambios.
                    </Typography>
                    <DialogContent sx={{justifyContent:"center"}}>
                      <Box >
                        {displayedProducts.map((product) => (
                          <Stack
                            justifyContent="center"
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{
                              
                              padding: "8px 0",
                              borderBottom: "1px solid #f0f0f0",
                            }}
                            key={product.id}
                          >
                            <TextField
                              type="number"
                              value={product.quantity}
                              onChange={(e) =>
                                handleQuantityChange(product.id, parseInt(e.target.value, 10))
                              }
                              sx={{ width: "80px", ml:2 }}
                            >
                              </TextField>
                              <Typography variant="body2" sx={{color:"rgba(108, 117, 147, 1)"}}>
                              unidades
                            </Typography>

                            <Typography variant="body2" sx={{color:"rgba(108, 117, 147, 1)", fontWeight:"600", fontSize:"15px"}}>
                              {product.name}
                            </Typography>
                          </Stack>
                        ))}
                      </Box>
                      <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                        <Pagination
                          count={Math.ceil(addProducts.length / itemsPerPage)}
                          page={page}
                          onChange={handlePageChange}
                        />
                      </Stack>
                    </DialogContent>
                    <Divider sx={{ mb: 2 }} />
                    <DialogTitle
                      onClick={handleClose}
                      sx={{
                        color: "rgba(240, 83, 28, 1)",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                    >
                      + Agregar otro producto
                    </DialogTitle>
                    <DialogActions sx={{ justifyContent: "center", mb: 2, mr: 2, ml: 2 }}>
                      <Button
                        sx={{
                          width: "160px",
                          border: "1px solid black",
                          backgroundColor: "#fff",
                          color: "black",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#f0f0f0",
                          },
                        }}
                        onClick={handleClose}
                      >
                        Cancelar
                      </Button>
                      <Button
                        sx={{ width: "160px" }}
                        onClick={handleSucces}
                        color="primary"
                        variant="contained"
                      >
                        Confirmar
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* Pop up Succes*/}
                  <Dialog open={openSuccess} onClose={handleClose}>
                    <DialogTitle
                      sx={{
                        color: "rgba(22, 23, 29, 1)",
                        fontWeight: "700",
                      }}
                    >
                      ¡Success!
                    </DialogTitle>
                    <Typography
                      variant="body2"
                      sx={{ marginTop: 0, marginLeft: 3, mb:2, color: "rgba(108, 117, 147, 1)", width:"300px" }}
                    >
                      The selected products have been successfully added to your inventory
                    </Typography>
                    <Divider />
                    <DialogTitle
                      onClick={handleClickOpen }
                      sx={{
                        color: "rgba(240, 83, 28, 1)",
                        fontWeight: "600",
                        cursor:"pointer",
                        
                      }}
                    >
                      + Add new product
                    </DialogTitle>
                    <DialogActions sx={{justifyContent:"center",mb:2, mr:2, ml:2}} >
                      <Button sx={{
                    width:"160px",
                    border: "1px solid black",
                    backgroundColor: "#fff",
                    color: "black",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }} onClick={handleClose}>Cancel</Button>
                      <Button sx={{width:"160px", }} onClick={handleClose} color="primary" variant="contained">
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                        
               {/* Paginación */}
               <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                 <Pagination
                   count={Math.ceil(products.length / PER_PAGE)}
                   page={page}
                   onChange={handleChangePage}
                   color="primary"
                 />
               </Box>
     </div>
   </div>
   )
 }





export default Inventory;
