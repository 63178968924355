import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  FormGroup,
  Checkbox,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project-imports
import AuthCard from "sections/auth/AuthCard";
import useAuth from "hooks/useAuth";

import { ThemeMode } from "config";

// assets
import { PatternFormat } from "react-number-format";
import { APICall } from "api";
import axios from "axios";
import bar from "assets/images/media.png";
import supply from "assets/images/supply.png";
import suppl from "assets/images/cover.png";
import Confetti from "react-confetti";


const steps = ["1", "2", "3", "4", "5", "6", "7"];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const regions = [
  "Aragon",
  "Asturias",
  "Islas Baleares",
  "Canarias",
  "Cantabria",
  "Castilla-La Mancha",
  "Castilla y León",
  "Cataluña",
  "Extremadura",
  "Galicia",
  "Comunidad de Madrid",
  "Región de Murcia",
  "Navarra",
  "Comunidad Valenciana",
  "País Vasco",
  "La Rioja",
];

function StepWrapper({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

StepWrapper.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
};

// ================================|| LOGIN ||================================ //

const Login3 = () => {
 
  const roles ={
    "Supplier":"supplier",
    "Importer":"importer",
    "BarOwner":"barOwner"
  }

  const [registerForm, setRegisterForm] = useState({
    // Shared fields
    name: "",
    lastName: "",
    telephone: "",
    email: "",
    idNumber: "",
    role: "BarOwner", // Default role, can be changed to Supplier or Importer

    // Role-specific fields
    barOwner: {
      isAlreadyOpen: "", // "I own a Bar" or "I am about to open a Bar"
      barName: "",
      barContactPhone: "",
      barEmail: "",
      barAddress: "",
      businessIdNumber: "",
      barType: "", // Enum options
    },

    supplier: {
      businessName: "",
      businessRegistrationNumber: "",
      supplierEmail: "",
      supplierContactPhone: "",
      itemsSupplied: {
        alcoholicBeverages: false,
        foodIngredients: false,
        nonAlcoholicBeverages: false,
        snacks: false,
        other: "", // Custom input
      },
      regionsOfOperation: [], // Array of strings for selected regions
      monthlyFigures: "", // Range options
      mostSoldProducts: "",
    },

    importer: {
      businessName: "",
      businessRegistrationNumber: "",
      importerEmail: "",
      importerContactPhone: "",
      itemsSupplied: {
        alcoholicBeverages: false,
        foodIngredients: false,
        nonAlcoholicBeverages: false,
        snacks: false,
        other: "", // Custom input
      },
      regionsOfOperation: [], // Array of strings for selected regions
      monthlyFigures: "", // Range options
      mostSoldProducts: "",
    },
  });


  const theme = useTheme();
  const { completeRegister, user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());


  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const borderColor =
    theme.palette.mode === ThemeMode.DARK
      ? theme.palette.secondary[200]
      : theme.palette.secondary.light;

  const isStepSkipped = (step) => skipped.has(step);




  const handleRegionChange = (e,role) => {
    const { name, checked } = e.target;

    const selectedRole=roles[role];
    setRegisterForm((prev) => {
      const updatedRegions = checked
        ? [...prev[selectedRole].regionsOfOperation, name] // Add region
        : prev[selectedRole].regionsOfOperation.filter((region) => region !== name); // Remove region
  
      return {
        ...prev,
        [selectedRole]: {
          ...prev[selectedRole],
          regionsOfOperation: updatedRegions,
        },
      };
    });
  };

  const handleSelectAll = (e,role) => {
    const { checked } = e.target;

    const selectedRole=roles[role];
    setRegisterForm((prev) => ({
      ...prev,
      [selectedRole]: {
        ...prev[selectedRole],
        regionsOfOperation: checked ? regions : [], // Select all or clear all
      },
    }));
  };
  

 
  


  const handleNext = (index) => {
    if (typeof index === "number") return setActiveStep(index);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = (index) => {
    if (typeof index === "number") return setActiveStep(index);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleSharedFieldChange = (field, value) => {
    setRegisterForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRoleFieldChange = (role, field, value) => {
    setRegisterForm((prev) => ({
      ...prev,
      [role]: {
        ...prev[role],
        [field]: value,
      },
    }));
  };

  const handleItemsSuppliedChange = (role,item, checked) => {
    setRegisterForm((prev) => ({
      ...prev,
      [role]: {
        ...prev[role],
        itemsSupplied: {
          ...prev[role].itemsSupplied,
          [item]: checked,
        },
      },
    }));
  };

  const handleRoleChange = (newRole) => {
    setRegisterForm((prev) => ({
      ...prev,
      role: newRole,
    }));
  };




  useEffect(() => {
    if (user && user.hasCompletedRegistration) {
      navigate("/menu-principal");
    }
  }, [user]);


  const barTypes = ["Sports Bar", "Cocktail Bar", "Dive Bar", "Wine Bar", "Pub", "Other"]
  const monthlyFigures = ["Less than €5,000",
  "€5,000 - €10,000",
  "€10,000 - €20,000",
  "€20,000 - €50,000",
  "More than €50,000"]

  
  const handleSubmit = async () => {
  try {
    const formCopy = { ...registerForm };
    const activeRole = formCopy.role;
    // Construcción del payload inicial
    const payload = {
      name: formCopy.name,
      lastName: formCopy.lastName,
      telephone: formCopy.telephone,
      email: formCopy.email,
      idNumber: formCopy.idNumber,
      role: activeRole ,
      barOwner: formCopy.barOwner || null,
      supplier: formCopy.supplier || null,
      importer: formCopy.importer || null,
    };

    // Validación de campos obligatorios
    validatePayload(payload);

    console.log("Submitting payload:", payload);

    // Llamada a la API
    const response = await APICall.completeRegistration(payload);

    if (response.user) {
      completeRegister({
        ...response.user,
        hasCompletedRegistration: true,
      });
      setActiveStep(4);
    } else {
      alert("Something went wrong. Please try again.");
    }
  }  catch (error) {
    if (error.response && error.response.data) {
      if (error.response.data.error === "duplicate") {
        const key = error.response.data.key;
        alert(`The ${key} is already in use. Please try a different one.`);
      } else {
        console.error("Error from server:", error.response.data);
        alert("Error: " + error.response.data.message);
      }
    }
  }
};

const validatePayload = (payload) => {
  // Validaciones comunes
  const requiredFields = ["name", "lastName", "email", "telephone", "idNumber", "role"];
  requiredFields.forEach((field) => {
    if (!payload[field]) {
      throw new Error(`${field} is required.`);
    }
  });

  // Validaciones específicas por rol
  if (payload.role === "BarOwner") {
    if (!payload.barOwner?.isAlreadyOpen) throw new Error("Bar opening status is required.");
    if (!payload.barOwner?.barName) throw new Error("Bar name is required.");
    if (!payload.barOwner?.barContactPhone) throw new Error("Bar contact phone is required.");
    if (!payload.barOwner?.barEmail) throw new Error("Bar email is required.");
    if (!payload.barOwner?.barAddress) throw new Error("Bar address is required.");
    if (!payload.barOwner?.businessIdNumber) throw new Error("Business ID number is required.");
    if (!payload.barOwner?.barType) throw new Error("Bar type is required.");
  }

  if (payload.role === "Supplier") {
    if (!payload.supplier?.businessName) throw new Error("Supplier business name is required.");
    if (!payload.supplier?.businessRegistrationNumber) throw new Error("Supplier registration number is required.");
    if (!payload.supplier?.supplierEmail) throw new Error("Supplier email is required.");
    if (!payload.supplier?.supplierContactPhone) throw new Error("Supplier contact phone is required.");
    if (!payload.supplier?.itemsSupplied) throw new Error("Items supplied is required.");
    if (!payload.supplier?.regionsOfOperation) throw new Error("Regions of operation are required.");
    if (!payload.supplier?.monthlyFigures) throw new Error("Monthly figures are required.");
    if (!payload.supplier?.mostSoldProducts) throw new Error("Most sold products are required.");
  }

  if (payload.role === "Importer") {
    if (!payload.importer?.businessName) throw new Error("Importer business name is required.");
    if (!payload.importer?.businessRegistrationNumber) throw new Error("Importer registration number is required.");
    if (!payload.importer?.importerEmail) throw new Error("Importer email is required.");
    if (!payload.importer?.importerContactPhone) throw new Error("Importer contact phone is required.");
    if (!payload.importer?.itemsSupplied) throw new Error("Items supplied is required.");
    if (!payload.importer?.regionsOfOperation) throw new Error("Regions of operation are required.");
    if (!payload.importer?.monthlyFigures) throw new Error("Monthly figures are required.");
    if (!payload.importer?.mostSoldProducts) throw new Error("Most sold products are required.");
  }
};
  const FormNavigator = ({ next, back, isFinish, step }) => {
    return (<Grid item xs={12}>
      <Stack direction="row" spacing={1}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => back()}
          fullWidth
        >
          Go Back
        </Button>
        <Button
          onClick={() => isFinish?handleSubmit():next()}
          variant="contained"
          color="primary"
          fullWidth
          disabled={getButtonDisabled(step)}
        >
          {isFinish ? "Finish" : "Continue"}
        </Button>
      </Stack>
    </Grid>)
  }

  const getButtonDisabled = (step) => {

    switch (step) {
      case 0: {
        return !(registerForm.name != "" && registerForm.idNumber != "" && registerForm.lastName != "" && registerForm.telephone != "")
      }
      case 1: {
        return !(registerForm.barOwner.barAddress != "" && 
        registerForm.barOwner.barContactPhone != "" && 
        registerForm.barOwner.barEmail != "" && 
        registerForm.barOwner.barName != "" && 
        registerForm.barOwner.barType != "" && 
        registerForm.barOwner.businessIdNumber != "" &&
        registerForm.barOwner.isAlreadyOpen !==""
        )
      }
      case 2: {
        return !(registerForm.supplier.businessName != "" && 
        registerForm.supplier.businessRegistrationNumber != "" && 
        registerForm.supplier.supplierContactPhone != "" && 
        registerForm.supplier.supplierEmail != "" && (
          registerForm.supplier.itemsSupplied.alcoholicBeverages!=false ||
          registerForm.supplier.itemsSupplied.foodIngredients!=false ||
          registerForm.supplier.itemsSupplied.nonAlcoholicBeverages!=false ||
          registerForm.supplier.itemsSupplied.snacks!=false ||
          registerForm.supplier.itemsSupplied.other!="" 
        )
        )
      }
      case 3: {
        return !(registerForm.importer.businessName != "" && 
        registerForm.importer.businessRegistrationNumber != "" && 
        registerForm.importer.importerContactPhone != "" && 
        registerForm.importer.importerEmail != "" && (
          registerForm.importer.itemsSupplied.alcoholicBeverages!=false ||
          registerForm.importer.itemsSupplied.foodIngredients!=false ||
          registerForm.importer.itemsSupplied.nonAlcoholicBeverages!=false ||
          registerForm.importer.itemsSupplied.snacks!=false ||
          registerForm.importer.itemsSupplied.other!="" 
        )
        )
      }
      case 5:{
        return (registerForm[roles[registerForm.role]].regionsOfOperation?.length==0)
      }
      case 6:{
        return !(registerForm[roles[registerForm.role]].monthlyFigures!="" &&registerForm[roles[registerForm.role]].mostSoldProducts!="")
      }
      default:
        return false;
    }



  }

  return (
    <div>
      {/*
      <Button style={{ background: "white", height: 100, cursor: "pointer" }} onClick={() => console.log(registerForm)}>PRINT FORM</Button> 
      */}
      {activeStep === 4 && (<Confetti
        width={width}
        height={height}
      />)}
      <Grid
        container
        spacing={3}
        sx={{ minHeight: "100%", alignContent: "space-between" }}
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div />
            <Typography color="secondary">
              Etapa
              <Typography
                component="span"
                variant="subtitle1"
                sx={{ display: "inline-block", margin: "0 5px" }}
              >
                {registerForm.role === "BarOwner"
                  ? activeStep == 1
                    ? activeStep + 1
                    : activeStep == 0
                      ? 1
                      : activeStep
                  : activeStep > 0
                    ? activeStep
                    : activeStep + 1}
              </Typography>
              de 7
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ "& > div": { margin: "24px auto", maxWidth: 800 } }}
        >
          <AuthCard border={false}>
            {activeStep === 4 ? (
              <>
                <Alert sx={{ my: 3 }}>
                  Cadastro finalizado! Redirecionando para o menu principal...
                </Alert>
                <Button
                  component={Link}
                  to={"/menu-principal"}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Ir para Menu Principal
                </Button>
              </>
            ) : (
              <>
                <StepWrapper value={activeStep} index={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Hey there! 👋 <br /> Welcome to Kanpaï
                        </Typography>
                        <Typography>
                          Tell us a little bit about you:
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>

                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="name">
                              Name
                            </InputLabel>
                            <OutlinedInput
                              id="name"
                              type="text"
                              placeholder="Name"
                              fullWidth
                              name="name"
                              value={registerForm.name}
                              onChange={(e) => handleSharedFieldChange("name", e.target.value)}
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="lastName">
                              Last Name
                            </InputLabel>
                            <OutlinedInput
                              id="lastName"
                              type="text"
                              placeholder="Last Name"
                              fullWidth
                              value={registerForm.lastName}
                              onChange={(e) => handleSharedFieldChange("lastName", e.target.value)}
                              name="lastName"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="email">
                              Email
                            </InputLabel>
                            <OutlinedInput
                              id="email"
                              type="text"
                              placeholder="email"
                              fullWidth
                              value={registerForm.email}
                              onChange={(e) => handleSharedFieldChange("email", e.target.value)}
                              name="email"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Contact Phone</InputLabel>
                            <PatternFormat
                              format="+## ### ### ##"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Contact Phone"
                              value={registerForm.telephone}

                              onChange={(e) => handleSharedFieldChange("telephone", e.target.value)}
                              name="telephone"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="idNumber">
                              ID Number
                            </InputLabel>
                            <OutlinedInput
                              id="idNumber"
                              type="text"
                              placeholder="ID Number"
                              fullWidth
                              name="idNumber"
                              value={registerForm.idNumber}

                              onChange={(e) => handleSharedFieldChange("idNumber", e.target.value)}
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack direction="row" spacing={1}>
                            <Button
                              onClick={() => {
                                handleNext(8);
                              }}
                              variant="contained"
                              disabled={getButtonDisabled(0)}
                              color="primary"
                              fullWidth
                            >
                              Next
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Stack spacing={1}>
                        <Typography variant="h3">What is your role?</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          "& .MuiFormLabel-root": {
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",

                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: 1,
                            paddingBottom: 2,
                          },
                          "& .MuiRadio-root.Mui-checked + .MuiFormLabel-root": {
                            boxShadow: `0 0 0 1px ${theme.palette.primary.main}, 0px 8px 24px rgba(27, 46, 94, 0.12)`,
                            borderColor: theme.palette.primary.main,
                            color: theme.palette.primary.main,
                            bgcolor: theme.palette.primary.lighter,
                          },
                        }}
                      >
                        <Grid
                          item
                          sm={4}
                          xs={4}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Radio
                            id="radioBarOwner"
                            checked={registerForm.role === "BarOwner"}
                            onChange={() => { handleRoleChange("BarOwner") }}
                            value="BarOwner"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            sx={{ display: "none" }}
                          />
                          <InputLabel
                            htmlFor="radioBarOwner"
                            sx={{
                              ml: "0 !important",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              width="100%"
                              src={bar}
                              style={{
                                padding: 0,
                              }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                mt: 3,
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              I own a Bar
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                mt: 1,
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal", // Ensures wrapping
                                overflowWrap: "break-word", // Ensures long words break
                                padding: "0 10px",
                              }}
                            >
                              Manage & optimize your inventory in real-time.
                            </Typography>
                          </InputLabel>
                        </Grid>

                        <Grid item sm={4} xs={4}>
                          <Radio
                            id="radioSupplier"
                            checked={registerForm.role === "Supplier"}
                            onChange={() => { handleRoleChange("Supplier") }}
                            value="Supplier"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "B" }}
                            sx={{ display: "none" }}
                          />
                          <InputLabel
                            htmlFor="radioSupplier"
                            sx={{
                              ml: "0 !important",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              width="100%"
                              src={supply}
                              style={{
                                padding: 0,
                              }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                mt: 3,
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              I am a Supplier
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                mt: 1,
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal", // Ensures wrapping
                                overflowWrap: "break-word", // Ensures long words break
                                padding: "0 10px",
                              }}
                            >
                              Showcase your products and manage your campaigns
                              with ease.
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid
                          item
                          sm={4}
                          xs={4}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Radio
                            id="radioEdit"
                            checked={registerForm.role === "Importer"}
                            onChange={() => { handleRoleChange("Importer") }}
                            value="Importer"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            sx={{ display: "none" }}
                          />
                          <InputLabel
                            htmlFor="radioEdit"
                            sx={{
                              ml: "0 !important",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              width="100%"
                              src={suppl}
                              style={{
                                padding: 0,
                              }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                mt: 3,
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              I am an Importer
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                mt: 1,
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal", // Ensures wrapping
                                overflowWrap: "break-word", // Ensures long words break
                                padding: "0 10px",
                              }}
                            >
                              Streamline your import operations and manage your
                              product portfolio.
                            </Typography>
                          </InputLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    <FormNavigator
                      back={() => {
                        handleBack(0)
                      }}
                      next={() => {
                        if (registerForm.role === "BarOwner") {
                          handleNext(1);
                        } else if (registerForm.role === "Supplier") {
                          handleNext(2);
                        } else if (registerForm.role === "Importer") {
                          handleNext(3);
                        }
                      }}

                      step={8}
                    />
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Tell us about your bar
                        </Typography>
                        <Typography>
                          Forneça suas informações pessoais
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              control={<Radio
                                value={true}
                                onChange={(e)=>{handleRoleFieldChange("barOwner","isAlreadyOpen",e.target.value)}}/>}
                              label="I own a Bar"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio 
                                value={false}
                            
                                onChange={(e)=>{handleRoleFieldChange("barOwner","isAlreadyOpen",e.target.value)}}/>}
                                label="I am about to open a Bar"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="barName">
                              Bar's Name
                            </InputLabel>
                            <OutlinedInput
                              id="barName"
                              type="text"
                              placeholder="Bar's Name"
                              fullWidth
                              value={registerForm.barOwner.barName}
                              onChange={(e) => { handleRoleFieldChange("barOwner", "barName", e.target.value) }}
                              name="barName"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Contact Phone</InputLabel>
                            <PatternFormat
                              format="+## ### ### ##"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Contact Phone"
                              value={registerForm.barOwner.barContactPhone}
                              onChange={(e) => { handleRoleFieldChange("barOwner", "barContactPhone", e.target.value) }}
                              name="barContactPhone"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="barEmail">
                              Email of Establishment
                            </InputLabel>
                            <OutlinedInput
                              id="barEmail"
                              type="text"
                              placeholder="Email of Establishment"
                              fullWidth
                              value={registerForm.barOwner.barEmail}
                              onChange={(e) => { handleRoleFieldChange("barOwner", "barEmail", e.target.value) }}
                              name="barEmail"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco">
                              Physical Address
                            </InputLabel>
                            <OutlinedInput
                              id="barAddress"
                              type="text"
                              placeholder="Physical Address"
                              fullWidth
                              value={registerForm.barOwner.barAddress}
                              onChange={(e) => { handleRoleFieldChange("barOwner", "barAddress", e.target.value) }}
                              name="barAddress"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Business ID Number</InputLabel>
                            <OutlinedInput
                              id="businessIdNumber"
                              type="text"
                              placeholder="Business ID Number"
                              fullWidth
                              value={registerForm.barOwner.businessIdNumber}
                              onChange={(e) => { handleRoleFieldChange("barOwner", "businessIdNumber", e.target.value) }}
                              name="businessIdNumber"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Type of Bar</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              value={registerForm.barOwner.barType}

                              onChange={(e) => { handleRoleFieldChange("barOwner", "barType", e.target.value) }}
                              name="barType"
                            >
                              {barTypes.map((bt) => (
                                <MenuItem key={bt} value={bt}>
                                  {bt}
                                </MenuItem>
                              ))}
                            </Select>
                          </Stack>
                        </Grid>
                        <FormNavigator
                          back={() => { handleBack(8) }}
                          next={async () => {
                            //await handleCreateBarOwner();
                            handleNext(4);
                          }}
                          isFinish
                          step={1}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>


                <StepWrapper value={activeStep} index={5}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Typography variant="h3" >
                          Where does your business operate?
                        </Typography>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={
                                  registerForm[roles[registerForm.role]].regionsOfOperation?.length ===
                                  regions?.length
                                }
                                indeterminate={
                                  registerForm[roles[registerForm.role]].regionsOfOperation?.length > 0 &&
                                  registerForm[roles[registerForm.role]].regionsOfOperation?.length <
                                    regions?.length
                                }
                                onChange={(e)=>handleSelectAll(e,registerForm.role)}
                              />
                              }
                              label="Select All"
                            />
                            <Divider sx={{ marginY: 1 }} />
                            <Grid container spacing={2}>
                              {regions.map((region, index) => (
                                <Grid item xs={12} sm={6} md={6} key={region}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                      checked={registerForm[roles[registerForm.role]].regionsOfOperation?.includes(
                                        region
                                      )}
                                      onChange={(e)=>handleRegionChange(e,registerForm.role)}
                                      name={region}
                                    />
                                    }
                                    label={region}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </FormGroup>
                        </Grid>
                        <FormNavigator
                          next={() => {
                            handleNext(6);
                          }}
                          back={() => 
                            registerForm.role=="Supplier"?
                            handleBack(2):handleBack(3)}
                          step={5}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          What are your approximate monthly figures?
                        </Typography>

                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Stack spacing={1}>
                              <InputLabel>Approximate monthly figures</InputLabel>
                          <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              value={registerForm[roles[registerForm.role]].monthlyFigures}
                              onChange={(e) => { handleRoleFieldChange(roles[registerForm.role], "monthlyFigures", e.target.value) }}
                              name="monthlyFigures"
                            >
                              {monthlyFigures.map((bt) => (
                                <MenuItem key={bt} value={bt}>
                                  {bt}
                                </MenuItem>
                              ))}
                            </Select>
                            </Stack>
                          </Grid>
                          <Stack spacing={1} sx={{ mt: 2 }}>
                            <InputLabel>What are your most sold products?</InputLabel>
                            <OutlinedInput
                              id="telefone"
                              type="text"
                              placeholder="Product Names"
                              fullWidth
                              value={registerForm[roles[registerForm.role]].mostSoldProducts}
                              onChange={(e) => { handleRoleFieldChange(roles[registerForm.role], "mostSoldProducts", e.target.value) }}
                              name="mostSoldProducts"
                            />
                          </Stack>
                        </Grid>
                        <FormNavigator next={() => {
                          handleNext(7);
                        }} back={() => handleBack(5)}
                          step={6}
                        />

                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Please upload your catalogue
                        </Typography>

                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Stack spacing={1}>
                              <InputLabel>Import Your Database</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth

                                disabled

                                name="sexo"
                              ></Select>
                              <InputLabel>Supported format: CSV, XSL/XLSX, ODS, TSV, JSON, or PDF</InputLabel>
                            </Stack>
                          </Grid>

                        </Grid>
                        <FormNavigator next={async () => {
                          // await handleCreateBarOwner();
                          handleNext(4);
                        }} back={() => handleBack(6)} isFinish
                          step={7}
                        > </FormNavigator>

                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Tell us about your business SUPPLIER?
                        </Typography>
                        <Typography>
                          Please fill in your business’s data so you can begin
                          managing your inventory.
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="businessName">
                              Business`s Name
                            </InputLabel>
                            <OutlinedInput
                              id="businessName"
                              type="text"
                              placeholder="Bar's Name"
                              fullWidth
                              value={registerForm.supplier.businessName}
                              onChange={(e) => {handleRoleFieldChange("supplier","businessName",e.target.value)}}
                              name="businessName"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>
                              Business Registration Number
                            </InputLabel>
                            <PatternFormat
                              format="##########"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Business Registration Number"
                              value={registerForm.supplier.businessRegistrationNumber}
                              onChange={(e) => {handleRoleFieldChange("supplier","businessRegistrationNumber",e.target.value)}}
                              name="businessRegistrationNumber"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="email">
                              Email of Establishment
                            </InputLabel>
                            <OutlinedInput
                              id="email"
                              type="text"
                              placeholder="Email of Establishment"
                              fullWidth
                              value={registerForm.supplier.supplierEmail}
                              onChange={(e) => {handleRoleFieldChange("supplier","supplierEmail",e.target.value)}}
                              name="supplierEmail"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Contact Phone</InputLabel>
                            <PatternFormat
                              format="+## ### ### ##"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Contact Phone"
                              value={registerForm.supplier.supplierContactPhone}
                              onChange={(e) => {handleRoleFieldChange("supplier","supplierContactPhone",e.target.value)}}
                              name="supplierContactPhone"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco-supplier">
                              What items does your business supply?
                            </InputLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.supplier.itemsSupplied.alcoholicBeverages}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("supplier","alcoholicBeverages", e.target.checked)
                                  }
                                />}
                                label="Alcoholic Beverages"
                              />
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.supplier.itemsSupplied.foodIngredients}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("supplier","foodIngredients", e.target.checked)
                                  }
                                />}
                                label="Food Ingredients"
                              />
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.supplier.itemsSupplied.nonAlcoholicBeverages}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("supplier","nonAlcoholicBeverages", e.target.checked)
                                  }
                                  />}
                                label="Non-Alcoholic Beverages"
                              />
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.supplier.itemsSupplied.snacks}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("supplier","snacks", e.target.checked)
                                  }
                                />}
                                label="Snacks"
                              />
                            </FormGroup>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco-supplier">
                              Others
                            </InputLabel>
                            <OutlinedInput
                              id="endereco-supplier"
                              type="text"
                              placeholder="Others"
                              fullWidth
                              value={registerForm.supplier.itemsSupplied.other}
                              onChange={(e) =>
                                handleItemsSuppliedChange("supplier","other", e.target.value)
                              }
                              name="other"
                            />
                          </Stack>
                        </Grid>
                        <FormNavigator
                          next={() => handleNext(5)}
                          back={() => handleBack(8)}
                          step={2}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Tell us about your business IMPORTER?
                        </Typography>
                        <Typography>
                          Please fill in your business’s data so you can begin
                          managing your inventory.
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="businessName">
                              Business`s Name
                            </InputLabel>
                            <OutlinedInput
                              id="businessName"
                              type="text"
                              placeholder="Bar's Name"
                              fullWidth
                              value={registerForm.importer.businessName}
                              onChange={(e) => {handleRoleFieldChange("importer","businessName",e.target.value)}}
                              name="businessName"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>
                              Business Registration Number
                            </InputLabel>
                            <PatternFormat
                              format="##########"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Business Registration Number"
                              value={registerForm.importer.businessRegistrationNumber}
                              onChange={(e) => {handleRoleFieldChange("importer","businessRegistrationNumber",e.target.value)}}
                              name="businessRegistrationNumber"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="email">
                              Email of Establishment
                            </InputLabel>
                            <OutlinedInput
                              id="email"
                              type="text"
                              placeholder="Email of Establishment"
                              fullWidth
                              value={registerForm.importer.importerEmail}
                              onChange={(e) => {handleRoleFieldChange("importer","importerEmail",e.target.value)}}
                              name="importerEmail"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Contact Phone</InputLabel>
                            <PatternFormat
                              format="+## ### ### ##"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Contact Phone"
                              value={registerForm.importer.importerContactPhone}
                              onChange={(e) => {handleRoleFieldChange("importer","importerContactPhone",e.target.value)}}
                              name="importerContactPhone"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco-importer">
                              What items does your business supply?
                            </InputLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.importer.itemsSupplied.alcoholicBeverages}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("importer","alcoholicBeverages", e.target.checked)
                                  }
                                />}
                                label="Alcoholic Beverages"
                              />
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.importer.itemsSupplied.foodIngredients}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("importer","foodIngredients", e.target.checked)
                                  }
                                />}
                                label="Food Ingredients"
                              />
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.importer.itemsSupplied.nonAlcoholicBeverages}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("importer","nonAlcoholicBeverages", e.target.checked)
                                  }
                                  />}
                                label="Non-Alcoholic Beverages"
                              />
                              <FormControlLabel
                                control={<Checkbox 
                                  checked={registerForm.importer.itemsSupplied.snacks}
                                  onChange={(e) =>
                                    handleItemsSuppliedChange("importer","snacks", e.target.checked)
                                  }
                                />}
                                label="Snacks"
                              />
                            </FormGroup>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco-supplier">
                              Others
                            </InputLabel>
                            <OutlinedInput
                              id="endereco-supplier"
                              type="text"
                              placeholder="Others"
                              fullWidth
                              value={registerForm.importer.itemsSupplied.other}
                              onChange={(e) =>
                                handleItemsSuppliedChange("importer","other", e.target.value)
                              }
                              name="other"
                            />
                          </Stack>
                        </Grid>
                        <FormNavigator
                          next={() => handleNext(5)}
                          back={() => handleBack(8)}
                          step={3}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
              </>
            )}
          </AuthCard>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {activeStep != steps?.length && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="baseline"
                sx={{ mb: { xs: -0.5, sm: 0.5 } }}
              ></Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login3;
