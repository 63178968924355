import React, { useState } from 'react';
import { Button, Typography, Box, Paper, Grid, Chip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Stack, Tooltip } from '@mui/material';
import { Trash, Edit2, Setting4, ProfileAdd } from 'iconsax-react';

function UserRoles() {
  const [openRoleForm, setOpenRoleForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const users = [
    { email: "admin@barcatorce.com", role: "Admin" },
    { email: "rocioestrada@barcatorce.com", role: "Colaborador" },
    { email: "nicolasprieto@barcatorce.com", role: "Invitación enviada" },
    { email: "diegoramirez@barcatorce.com", role: "Usuario no registrado" }
  ];

  const handleOpenRoleForm = (user) => {
    setSelectedUser(user);
    setOpenRoleForm(true);
  };

  const handleCloseRoleForm = () => {
    setOpenRoleForm(false);
    setSelectedUser(null);
  };

  const handleSaveRole = () => {
    console.log('Saving role for:', selectedUser);
    setOpenRoleForm(false);
  };

  const handleOpenDeleteDialog = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedUser(null);
  };

  const handleDeleteUser = () => {
    console.log('Deleting user:', selectedUser);
    setOpenDeleteDialog(false);
  };

  return (
    <Box sx={{ padding: 4, minHeight: '100vh' }}>
      {/* Title */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
    <Typography variant="h4" gutterBottom>
      Usuarios
    </Typography>

    <Button
      variant="contained"
      startIcon={<ProfileAdd />}
      sx={{ backgroundColor: '#FF5722', '&:hover': { backgroundColor: '#FF7043' }, margin:'0px 20px 10px' }}
    >
      Agregar nuevo usuario
    </Button>
  </Box>

      {/* User List */}
      <Paper sx={{ padding: 2, backgroundColor: 'transparent', boxShadow: 'none' }}>
    {/* Mapea y renderiza los usuarios aquí */}
    {users.map((user, index) => (
      <Grid container alignItems="center" spacing={2} sx={{ marginBottom: 1 }} key={index}>
        {/* Email */}
        <Grid item xs={6}>
          <Typography>{user.email}</Typography>
        </Grid>

        {/* Role */}
        <Grid item xs={2}>
          {user.role === "Admin" && <Chip label="Admin" sx={{ backgroundColor: '#B5D98C', color: '#3D581D', fontWeight:"700" }} />}
          {user.role === "Colaborador" && <Chip label="Colaborador" sx={{ backgroundColor: '#d6e4f5', color: 'rgba(12, 76, 106, 1)',fontWeight:"700" }} />}
          {user.role === "Invitación enviada" && <Chip label="Invitación enviada" sx={{ backgroundColor: 'rgba(240, 83, 28, 0.2)', color: 'rgba(240, 83, 28, 1)', fontWeight:"700" }} />}
          {user.role === "Usuario no registrado" && <Chip label="Usuario no registrado" sx={{ backgroundColor: '#F44336', color: 'white' }} />}
        </Grid>

        {/* Edit Button */}
        <Grid item xs={2}>
          <Tooltip title="Editar Rol">
          <IconButton
                color="inherit"
                onClick={() => handleOpenRoleForm(user)}
                sx={{
                    width:"100px",
                    backgroundColor: 'transparent',
                    border: '1px solid black',
                    borderRadius: '4px',  // Agregar border-radius
                    color: 'black',  // Letras negras
                    '&:hover': {
                    backgroundColor: 'transparent', // Fondo gris claro en hover
                    borderColor: 'black',  // Mantener el borde negro en hover
                    }
                }}  
                 >
                  <Edit2 sx={{ marginRight: 1 }} /> {/* Espacio entre el ícono y el texto */}
                    <Typography variant="body2" sx={{ color: 'black' }}>
                        Editar
                    </Typography>
           </IconButton>
          </Tooltip>
        </Grid>

        {/* More Options */}
        <Grid item xs={2}>
          <Tooltip title="Más opciones">
            <IconButton color="inherit" onClick={() => handleOpenDeleteDialog(user)}>
              <Trash />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    ))}
  </Paper>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', marginTop: 2 }}>
        <Button variant="outlined" color="inherit">
          Cancelar
        </Button>
        <Button variant="contained" sx={{ backgroundColor: '#ff6d1f', '&:hover': { backgroundColor: '#e55d1b' }, marginLeft:"30px" }}>
          Guardar
        </Button>
      </Box>

      {/* Edit Role Dialog */}
      <Dialog open={openRoleForm} onClose={handleCloseRoleForm} maxWidth="sm" fullWidth>
        <DialogTitle>Editar Rol</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>Email: {selectedUser?.email}</Typography>
            <TextField
              select
              label="Rol"
              value={selectedUser?.role || ''}
              onChange={(e) => setSelectedUser((prev) => ({ ...prev, role: e.target.value }))}
              fullWidth
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Colaborador">Colaborador</MenuItem>
              <MenuItem value="Invitación enviada">Invitación enviada</MenuItem>
              <MenuItem value="Usuario no registrado">Usuario no registrado</MenuItem>
            </TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
  <Button onClick={handleCloseRoleForm}>Cancelar</Button>
  <Button
    onClick={handleSaveRole}
    variant="contained"
    sx={{
      backgroundColor: '#ff6d1f', // Fondo naranja
      color: '#ffffff', // Texto blanco explícitamente
      '&:hover': {
        backgroundColor: '#e55d1b', // Un tono más oscuro de naranja para el hover
      },
      textTransform: 'none', // Para evitar que el texto se transforme a mayúsculas si es el comportamiento predeterminado
    }}
  >
    Guardar
  </Button>
</DialogActions>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Eliminar Usuario</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar a {selectedUser?.email}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button onClick={handleDeleteUser} color="error" variant="contained">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default UserRoles;
