import { Box, Typography, Button, Paper, List, ListItem, ListItemText, IconButton } from '@mui/material';
import Calendar from 'react-calendar';
import '../orderHistory/calendar.css';
import { More } from 'iconsax-react';  

// Estilos personalizados para el calendario
const customStyles = {
  calendarWrapper: {
    backgroundColor: '#F4F6F8', // Fondo claro
    borderRadius: '12px',
    border: "none", // Bordes redondeados
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Sombra ligera
  },
  calendarHeader: {
    display: 'flex',
    marginBottom: "20px",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  calendar: {
    borderRadius: '20px',
    boxShadow: "none",
    fontFamily: "Inter var",
  },
  button: {
    backgroundColor: 'rgba(253, 237, 231, 1)', // Color de fondo del botón
    color: 'rgba(240, 83, 28, 1)',
    borderRadius: '8px',
    padding: '4px 15px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: '#ffd7bb', // Color de fondo al pasar el mouse
    },
  },
  eventList: {
    marginTop: '20px',
  },
  listItem: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listItemText: {
    fontSize: '14px',
  },
  iconButton: {
    padding: '5px',
  }
};

const events = [
  { date: '01', title: 'Order shipped to Filippo' },
  { date: '22', title: 'Order shipped to Filippo' },
  { date: '28', title: 'Order shipped to Filippo' },
];

const CalendarSection = ({ calendarDate, setCalendarDate }) => {
  return (
    <Box sx={customStyles.calendarWrapper}>
      <Box sx={customStyles.calendarHeader}>
        <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: "700" }}>
          Calendar
        </Typography>
        <Button variant="contained" size="small" sx={customStyles.button}>
          View Calendar
        </Button>
      </Box>

      {/* Calendario con iconos para fechas especiales */}
      <Paper sx={customStyles.calendar}>
        <Calendar
          onChange={setCalendarDate}
          value={calendarDate}
          sx={{
            '.react-calendar': {
              border: 'none !important', // Eliminar el borde del calendario
            },
            '.react-calendar__tile': {
              border: 'none !important', // Eliminar los bordes de las celdas
            },
            '.react-calendar__navigation': {
              border: 'none !important', // Eliminar los bordes de los controles de navegación
            }
          }}
        />
      </Paper>

      {/* Lista de eventos debajo del calendario */}
      <Box sx={customStyles.eventList}>
        <List>
          {events.map((event, index) => (
            <ListItem key={index} sx={customStyles.listItem}>
              <ListItemText 
                primary={`${event.date}: ${event.title}`} 
                sx={customStyles.listItemText} 
              />
              <IconButton sx={customStyles.iconButton}>
                <More size={12} color="black" />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CalendarSection;