import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import { CardContent, Divider } from "@mui/material";
import {
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from "@mui/material";
import { ArrowRight3, More } from "iconsax-react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Paper from "@mui/material/Paper";
import ReactApexChart from "react-apexcharts";
import widgets from "../extra-pages/data.json";
import { Check, Message, Eye, DocumentDownload } from "iconsax-react"





const Actions = () => (
  <Box>
     <IconButton size="small">
       <Check />
     </IconButton>
     <IconButton size="small">
       <Message />
     </IconButton>
     <IconButton size="small">
       <Eye />
     </IconButton>
     <IconButton size="small">
     <DocumentDownload />
     </IconButton>
   </Box>
);

const Payment = () => {

  const rows = [
    {
      orderNumber: "KAN-24-07-008",
      total: "€2400,25",
      client: "Bar Manolo",
      date: "2 días",
      status: "Pago retrasado",
      statusColor: "error",
    },
    {
      orderNumber: "KAN-24-07-008",
      total: "€2400,25",
      client: "Bar Manolo",
      date: "5 días",
      status: "Fallo en el pago",
      statusColor: "warning",
    },
    {
      orderNumber: "KAN-24-07-008",
      total: "€2400,25",
      client: "Bar Manolo",
      date: "5 días",
      status: "Pago Pendiente",
      statusColor: "info",
    },
    {
      orderNumber: "KAN-24-07-008",
      total: "€2400,25",
      client: "Bar Manolo",
      date: "7 días",
      status: "Revisar pago",
      statusColor: "error",
    },
    {
      orderNumber: "KAN-24-07-008",
      total: "€2400,25",
      client: "Bar Manolo",
      date: "30 días",
      status: "Revisar pago",
      statusColor: "error",
    },
    {
      orderNumber: "KAN-24-07-008",
      total: "€2400,25",
      client: "Bar Manolo",
      date: "60 días",
      status: "Pago Pendiente",
      statusColor: "info",
    },
  ];
  return (
  <Grid container spacing={3}>
     <Grid item xs={12}>
       <Grid container spacing={2}>
         <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
           <Box
             sx={{
               backgroundColor: "#FBFAFE",
               p: 2,
               borderRadius: 1,
               mb: 2,
               border: "1px solid #E9E5F1",
             }}
           >
             <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
               {" "}
               Total Payments{" "}
             </Typography>
             <Box
               sx={{
                 display: "flex",
                 justifyContent: "space-between",
                 alignItems: "center",
               }}
             >
               <Typography variant="h4" fontWeight="bold">
                 {" "}
                 $6480,20{" "}
               </Typography>

             </Box>
           </Box>
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
           <Box
             sx={{
               backgroundColor: "#FBFAFE",
               p: 2,
               borderRadius: 1,
               mb: 2,
               border: "1px solid #E9E5F1",
             }}
           >
             <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
               {" "}
               Paid{" "}
             </Typography>
             <Box
               sx={{
                 display: "flex",
                 justifyContent: "space-between",
                 alignItems: "center",
               }}
             >
               <Typography variant="h4" fontWeight="bold">
                 {" "}
                 $4626,36{" "}
               </Typography>

             </Box>
           </Box>
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
           <Box
             sx={{
               backgroundColor: "#FBFAFE",
               p: 2,
               borderRadius: 1,
               mb: 2,
               border: "1px solid #E9E5F1",
             }}
           >
             <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
               {" "}
               Pending{" "}
             </Typography>
             <Box
               sx={{
                 display: "flex",
                 justifyContent: "space-between",
                 alignItems: "center",
               }}
             >
               <Typography variant="h4" fontWeight="bold">
                 {" "}
                 $1853,84{" "}
               </Typography>

             </Box>
           </Box>
         </Grid>
       </Grid>
     </Grid>
     <Grid item xs={12}>
     <TableContainer component={Paper}>
           <Table>
             {/* Table Head */}
             <TableHead>
               <TableRow>
                 <TableCell>Número de orden</TableCell>
                 <TableCell>Total</TableCell>
                 <TableCell>Cliente</TableCell>
                 <TableCell>Fecha</TableCell>
                 <TableCell>Estado</TableCell>
                 <TableCell>Acciones</TableCell>
               </TableRow>
             </TableHead>

             {/* Table Body */}
             <TableBody>
               {rows.map((row, index) => (
                 <TableRow key={index}>
                   <TableCell>{row.orderNumber}</TableCell>
                   <TableCell>{row.total}</TableCell>
                   <TableCell>{row.client}</TableCell>
                   <TableCell>
                     <Typography component="span" sx={{ textDecoration: "underline" }}>
                       {row.date}
                     </Typography>
                   </TableCell>
                   <TableCell>
                     <Typography
                       component="span"
                       sx={{
                         color: (theme) => theme.palette[row.statusColor].main,
                         backgroundColor: (theme) =>
                           theme.palette[row.statusColor].light,
                         padding: "4px 8px",
                         borderRadius: "4px",
                         fontSize: "0.875rem",
                         color: "#fff",
                       }}
                     >
                       {row.status}
                     </Typography>
                   </TableCell>
                   <TableCell>
                     <Actions />
                   </TableCell>
                 </TableRow>
               ))}
             </TableBody>
           </Table>
         </TableContainer>
     </Grid>
  </Grid>
  );
};

export default Payment;
