// third-party
import { sub } from 'date-fns';
import { Chance } from 'chance';
import lodash from 'lodash';

const chance = new Chance();

export const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i += 1) {
    arr.push(i);
  }
  return arr;
};

const bares = [
  "Filippo Bar", "La Bodega del Sol",
  "El Rincón de los Sueños",
  "Taberna La Alhambra",
  "Cervecería El Olivo",
  "Bar Mar y Sierra" 
];

const order = [
  "Order #12390234098",
  "Order #12390234098",
  "Order #12390234098"
];



const liquorType = [
  "Vodka",
  "Whisky",
  "Ron",
  "Tequila",
  "Gin",
  "Brandy",
  "Cognac",
  "Mezcal",
  "Beer",
  "Baileys Irish Cream"
];

  const units = [
  "24-unit ",
  "6-pack",
  "12-unit ",
  "48-unit ",
  "3-pack",
  "18-unit ",
  "8-unit set",
  "10-pack",
  "30-unit "
];

const volume = [
"33cl ",
"70cl ",
"5l ",
"33cl ",
"70cl ",
"5l ",
"33cl ",
"70cl ",
"5l ",
"33cl "
];

const quantity = [
 "132",
  "22 ",
  "33cl ",
  "132 ",
  "22 ",
  "33cl ",
  "132 ",
  "22 ",
  "33cl ",
  "132 ",
  "33cl"

];

const price = [
  "€15,32",   
 "€5.5",  
  "€8",    
  "€12.5",
  "€18",   
  "€21",   
  "€25" 
];
const prod =[
  "1906 Reserva especial",
  "Águila sin filtrar",
  "Estrella Galicia Especial",
  "Alhambra Reserva",
  "Johnny Walker Black",
  "José Cuervo Platino",
  "Hendrick’s",
  "Barceló",
  "818",
];



const skills = [
  'UI Design',
  'Mobile App',
  'Web App',
  'UX',
  'Wireframing',
  'Prototyping',
  'Backend',
  'React',
  'Angular',
  'Javascript',
  'HTML',
  'ES6',
  'Figma',
  'Codeigniter'
];

const time = ['just now', '1 day ago', '2 min ago', '2 days ago', '1 week ago', '1 year ago', '5 months ago', '3 hours ago', '1 hour ago'];

// ==============================|| CUSTOM FUNCTION - TABLE DATA ||============================== //

function mockData() {
  return {
    id: (index) => `${chance.bb_pin()}${index}`,
    email: chance.email({ domain: 'gmail.com' }),
    contact: chance.phone(),
    datetime: sub(new Date(), {
      days: chance.integer({ min: 0, max: 30 }),
      hours: chance.integer({ min: 0, max: 23 }),
      minutes: chance.integer({ min: 0, max: 59 })
    }),
    boolean: chance.bool(),
    role: chance.profession(),
    company: chance.company(),
    address: {
      full: `${chance.address()}, ${chance.city()}, ${chance.country({ full: true })} - ${chance.zip()}`,
      country: chance.country({ full: true })
    },
    name: {
      first: chance.first(),
      last: chance.last(),
      full: chance.name()
    },
    text: {
      title: chance.sentence({ words: chance.integer({ min: 4, max: 12 }) }),
      sentence: chance.sentence(),
      description: chance.paragraph
    },
    number: {
      percentage: chance.integer({ min: 0, max: 100 }),
      rating: chance.floating({ min: 0, max: 5, fixed: 2 }),
      status: (min, max) => chance.integer({ min, max }),
      age: chance.age(),
      amount: chance.integer({ min: 1, max: 10000 })
    },
    image: {
      product: (index) => `product_${index}`,
      avatar: (index) => `avatar_${index}`
    },
    skill: lodash.sampleSize(skills, chance.integer({ min: 2, max: 6 })),
    time: lodash.sampleSize(time),
    bar: lodash.sample(bares),
    order: lodash.sample(order),
    liquorType: lodash.sampleSize(liquorType),
    units: lodash.sampleSize(units),
    volume: lodash.sampleSize(volume),
    quantity: lodash.sampleSize(quantity),
    price: lodash.sampleSize(price),
    prod: lodash.sampleSize(prod)
  };
}

export default mockData;
